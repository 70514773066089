import { ref, onMounted, watch, nextTick, computed} from 'vue';
import jQuery from 'jquery';
import { useRouter } from 'vue-router'; 
import axios from 'axios';
import Home_navbar from '@/components/Home/Home_Navbar/HomeNavbarView.vue';
import View_product from '@/views/Customer_Management/Customer_Search_Products/SearchProductView.vue';
import Filter_product from '@/components/Products/FilterProductView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import EventBus from '@/eventBus.js';
import videojs from 'video.js';

export default {
  name: 'HomeNavbarView',
  components: {
    Home_navbar,
    View_product,
    Filter_product,
    Influencer_navbar

  },
  data(){
    return {
      selected_department_name: '',
      selected_category_name: '',
      FETCH_VIDEOS_URL:'',
      reels: [],
      fullScreenReels: [],
      originalReels: [],
      page: 1,
      limit: 5,
      isFetching: false,
      isFullScreenMode: false,
      allDataLoaded: false,
      players: {},
      currentPlayingIndex: null,
      fullScreenVideoIndex: null,
      debounceTimer: null,
      UserLoggedIn: false, // Add a property to track login status
      currentVideo:null,
      pipVideoIndex: null,
      showSizes: false,
      selectedProduct: null,
      selectedSize: null,
      sizeProductId: null,
      currentPipProducts: [],
      isInPiPMode: false,
      addedToWishlist: false,

    }
  },
  created() {
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.on('roleChanged', (newRole) => {
      this.role = newRole;
    });
  },
  beforeDestroy() {
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });
    EventBus.off('roleChanged');
  },
  computed: {
    currentPlayingVideo() {
      if (this.currentPlayingIndex !== null && this.reels.length > 0) {
        return this.reels[this.currentPlayingIndex];
      }
      return null;
    }
  },


  methods:{
    handleProductClick(product) {
      this.selectedProduct = product;
      this.sizes = this.selectedProduct.sizes;
      this.showSizes = !this.showSizes;
    },
    checkLoginStatus() {
      this.UserLoggedIn = !!localStorage.getItem('token');
    },
    navigateToBrands() {
      this.$router.push('/view_brands');
    },
    navigateToInfluencers() {
      this.$router.push('/View_Influencer');
    },
    navigateToCollection() {
      this.$router.push('/new_collection');
    },

    isLoggedIn() {
      const token = localStorage.getItem('token');
      return token !== null;
    },
    getLocalStorage(name) {
      const value = localStorage.getItem(name);
      return value ? JSON.parse(value) : null;
    },

    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },

   
    async fetchCartDetails() {
      const cart = this.getLocalStorage('cart') || [];
    
      for (const item of cart) {
      
          const GET_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/fetch_image/${item.id}/`;
          const response = await fetch(GET_IMAGE_URL);
          
          if (!response.ok) {
            throw new Error('Failed to fetch image URL');
          }
          const imageData = await response.json();
          item.image = imageData;
      }
    
      this.cartItems = cart;
      this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
      this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
      EventBus.emit('cart-updated', this.cartQuantity);
    }, 

    async handleAddToCart(size) {
      // Add size to product object
      this.selectedSize = size.size;
      this.sizeProductId = size.product_id;
    },
    async addToCart(product) {
      this.showSizes = false; // Hide sizes dropdown after adding to cart
      const quantity = 1; // Use the selected quantity
      const productId = this.sizeProductId;

      if (this.isLoggedIn()) { // Function to check if the user is logged in
        try {
          const token = localStorage.getItem('token');
          const ADD_TO_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
          await fetch(ADD_TO_CART_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product_id: productId,
              quantity: quantity
            })
          });

          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        } catch (error) {
          console.error('Error adding to cart:', error);
        }
      } else {
        // Handle the case for guest users
        const cart = this.getLocalStorage('cart') || [];

        const existingProduct = cart.find(item => item.product_id === this.sizeProductId && item.size === this.selectedSize);
        if (existingProduct) {
          existingProduct.quantity += quantity;
        } else {
          cart.push({
            id: product.product_id,
            product_name: product.product.product_name,
            quantity: quantity,
            price: product.price,
            color: product.color,
            size: this.selectedSize,
            brand: product.product.brand__brand_name,
            image: product.product.image_url,
            product_id: productId
          });
        }
        this.setLocalStorage('cart', cart);
        this.fetchCartDetails().then(() => {
          EventBus.emit('cart-updated', this.cartQuantity);
          if (this.isRightSidebarOpen) {
            this.isRightSidebarOpen = true;
            EventBus.emit('update-cart');
          } else {
            EventBus.emit('toggle-right-sidebar');
          }
        })
      }
    },

    async addToWishlist(product_id) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.showLoginAlert = true;
        setTimeout(() => {
          this.showLoginAlert = false;
        }, 5000);
        return;
      }
    
      // Prepare the request data
      const formData = new FormData();
      formData.append('productdetail_ids', product_id);
    
      try {
        const ADD_TO_WISHLIST_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_wishlist/`;
        const response = await fetch(ADD_TO_WISHLIST_URL, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        });
    
        const result = await response.json();
        if (response.ok && response.status === 201) {
          // If the response is successful
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else if (response.status === 200) {
          // Handle case when the product is already in the wishlist (409 Conflict)
          this.addedToWishlist = true;
          setTimeout(() => {
            this.addedToWishlist = false;
          }, 2000);
        } else {
          // If there's an error in the API call (e.g., validation error)
          alert(result.error || 'Failed to add product to the wishlist.');
        }
      } catch (error) {
        // Handle unexpected errors
        alert('Something went wrong. Please try again.');
      }
    },

    async fetchReelData() {

      if (this.isFetching || this.allDataLoaded) return;
    
      try {
    
        this.isFetching = true;
    
        this.FETCH_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
    
        const response = await fetch(`${this.FETCH_VIDEOS_URL}?page=${this.page}&limit=${this.limit}`);
    
        if (!response.ok) {
    
          throw new Error(`HTTP error! status: ${response.status}`);
    
        }
    
        const data = await response.json();
    
        if (data.length === 0) {
    
          this.allDataLoaded = true;
    
        } else {
    
          this.reels.push(...data);
    
          this.page++;
     
          // Initialize Video.js and Observers after new reels are rendered
    
          this.$nextTick(() => {
    
            this.initializeVideoJS();
    
            this.initializeVideoObserver();
    
          });
    
        }
    
      } catch (error) {
    
        console.error("Error fetching reel data:", error);
    
      } finally {
    
        this.isFetching = false;
    
      }
    
    },
     
    initializeVideoJS() {
      this.reels.forEach((_, index) => {
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) ? this.$refs['videoPlayer_' + index][0] : this.$refs['videoPlayer_' + index];
    
        if (videoEl instanceof HTMLElement && !this.players[index]) {
    
          const videoElement = videoEl.querySelector('video');
    
          videoElement.setAttribute('playsinline', '');
    
          const player = videojs(videoElement, {
            autoplay: 'muted', // Browser-friendly muted autoplay
            muted: true, // Required for autoplay
            controls: true,
            preload: "auto",
            loop: true,
            html5: {
              vhs: { overrideNative: true }, // Force HTML5 playback
              nativeVideoTracks: false // Disable native tracks for better control
            }
          });
    
          this.players[index] = player;
     
          // Remove play button overlay once video starts playing
          videoElement.addEventListener('play', () => {
            const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
            if (playButtonOverlay) {
              playButtonOverlay.style.display = 'none';
            }
          });
     
          // Ensure play button overlay reappears if video is paused
          videoElement.addEventListener('pause', () => {
    
            if (!this.userInteracted) {
              const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
              if (playButtonOverlay) {
                playButtonOverlay.style.display = 'block';
    
              }
    
            }
    
          });
     
          // Ensure overlay is clickable by adding a click event listener
          const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
          if (playButtonOverlay) {
            playButtonOverlay.addEventListener('click', (event) => {
              event.stopPropagation(); // Prevent click from propagating to the video element
              this.enableAutoplay();
                playButtonOverlay.style.display = 'none';
            });
    
          }
    
        }
    
      });
    
    },
     
    initializeInfiniteScroll() {
      this.$nextTick(() => {
        const sentinel = this.$refs.scrollSentinel;
        if (sentinel) {
          const observer = new IntersectionObserver(
            (entries) => {
              if (entries[0].isIntersecting && !this.isFetching) {
                this.fetchReelData();
              }
            },
            {
              root: null,
              rootMargin: "100px",
              threshold: 0,
            }
          );
          observer.observe(sentinel);
        }
      });
    
    },
     
    initializeVideoObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.75,
      };
    
      if (!('IntersectionObserver' in window)) {
        console.warn('IntersectionObserver is not supported in this browser. Videos may not automatically play/pause.');
        return;
      }
    
      const observer = new IntersectionObserver((entries) => {

        entries.forEach((entry) => {
          const videoIndex = parseInt(entry.target.dataset.index, 10);
          if (videoIndex !== -1 && this.players[videoIndex]) {
            if (entry.isIntersecting) {
              if (!this.isFullScreenMode || this.fullScreenVideoIndex === videoIndex) {

                this.setCurrentPlaying(videoIndex);
                const playPromise = this.players[videoIndex].play();
    
                if (playPromise !== undefined) {
                  playPromise.catch((error) => {
                    console.error('Autoplay prevented:', error);
                  });
                }
              }
            } else {
              this.players[videoIndex].pause();
            }
          }
        });
      }, options);
    
      this.reels.forEach((_, index) => {
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + index]) 
          ? this.$refs['videoPlayer_' + index][0] 
          : this.$refs['videoPlayer_' + index];
    
        if (videoEl) {

          const videoElement = videoEl.querySelector('video');
          videoElement.dataset.index = index;
          observer.observe(videoElement);
        }
      });
    },

    openFullScreen(video, index) {
  
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      if (!this.originalReels.length) {
        this.originalReels = [...this.reels];
      }
    
      if (!this.originalReels || !this.originalReels[index]) {
        console.error("Invalid index or reels not loaded");
        return;
      }
    
      this.fullScreenReels = [...this.originalReels];
      this.isFullScreenMode = true;
      this.fullScreenVideoIndex = index;
      this.currentPlayingIndex = index;
    
      window.addEventListener("wheel", this.handleScrollInFullScreen);
    
      this.$nextTick(() => {
        this.playVideoAtIndex(index);
      });
    },
    
    handleScrollInFullScreen(event) {
      if (!this.isFullScreenMode) return;
  
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
  
      // Debounce: Execute the navigation logic after a delay
      this.debounceTimer = setTimeout(() => {
        const direction = event.deltaY > 0 ? 1 : -1;
        this.navigateFullScreen(direction);
      }, 200); // Adjust the delay if needed
    },
    
    navigateFullScreen(direction) {
      if (!this.isFullScreenMode || !this.fullScreenReels.length) return;
    
      let nextIndex = this.fullScreenVideoIndex + direction;
    
      if (nextIndex < 0) {
        nextIndex = 0;
      } else if (nextIndex >= this.fullScreenReels.length) {
        nextIndex = this.fullScreenReels.length - 1;
      }
    
      if (nextIndex === this.fullScreenVideoIndex) {
        console.error("No more videos in this direction.");
        return;
      }
    
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      this.fullScreenVideoIndex = nextIndex;
      this.currentPlayingIndex = nextIndex;
    
      this.$nextTick(() => {
        this.playVideoAtIndex(nextIndex);
      });
    },
    
    playVideoAtIndex(index) {
      this.reels.forEach((_, i) => {
        const videoEl = Array.isArray(this.$refs[`videoPlayer_${i}`])
          ? this.$refs[`videoPlayer_${i}`][0]
          : this.$refs[`videoPlayer_${i}`];
    
        if (videoEl && i !== index) {
          const videoElement = videoEl.querySelector("video");
          if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0; // Reset the video
          }
        }
      });
    
      // Play the selected video
      const videoEl = Array.isArray(this.$refs[`videoPlayer_${index}`])
        ? this.$refs[`videoPlayer_${index}`][0]
        : this.$refs[`videoPlayer_${index}`];
    
      if (videoEl) {
        const videoElement = videoEl.querySelector("video");
        if (videoElement) {
          videoElement.muted = true;
          videoElement.setAttribute("playsinline", "true");
          videoElement.setAttribute("autoplay", "true");
          videoElement.setAttribute("preload", "auto");
          videoElement.setAttribute("controls", "true");
          videoElement.scrollIntoView({ behavior: "smooth", block: "center" });
    
          const playPromise = videoElement.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error("Autoplay prevented:", error);
              videoElement.controls = true; // Enable controls as fallback
            });
          }
        }
      }
    },

    exitFullScreen() {
      this.isFullScreenMode = false;
      this.fullScreenReels = [];
      this.fullScreenVideoIndex = 0;
      this.currentPlayingIndex = null;

      window.removeEventListener("wheel", this.handleScrollInFullScreen);
    },

     
    setCurrentPlaying(index) {
      if (this.currentPlayingIndex === index) return;
      // Pause the current playing video if it exists
      if (this.currentPlayingIndex !== null && this.players[this.currentPlayingIndex]) {
        this.players[this.currentPlayingIndex].pause();
      }
    
      // Update the current playing index
      this.currentPlayingIndex = index;

      const videoEl = Array.isArray(this.$refs['videoPlayer_' + index])
        ? this.$refs['videoPlayer_' + index][0]
        : this.$refs['videoPlayer_' + index];

      if (videoEl) {
        const videoElement = videoEl.querySelector('video');
        if (videoElement) {
          // Initialize the player if not already initialized
          if (!this.players[index]) {
            this.players[index] = videojs(videoElement, {
              autoplay: true,
              muted: true, // Ensure videos are muted for autoplay
              controls: true,
              preload: "auto",
              loop: true,
              html5: {
                vhs: {
                  overrideNative: false, // Use native playback where possible
                },
                nativeVideoTracks: true,
                nativeAudioTracks: true,
                nativeTextTracks: true,
              },
            });
          }
          videoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
  

    async enterPictureInPicture(reel, index) {

      const videoElement = document.getElementById(`video-${reel.id}`);
      
      if (!videoElement) {
        console.error('Video element not found!');
        return;
      }

      try {

        if (document.pictureInPictureElement) {
          await document.exitPictureInPicture();
        }
        // Enter PiP mode
        await videoElement.requestPictureInPicture();

        // Save the current PiP index
        this.isInPiPMode = true;
        this.pipVideoIndex = index;
        // Add event listener for leavepictureinpicture event
        this.currentPipProducts = reel.products.filter((p) => p.product_details); // Store product details

        videoElement.addEventListener('leavepictureinpicture', this.handleLeavePictureInPicture);
      } catch (error) {
        console.error('Failed to enter Picture-in-Picture mode:', error);
      }
    },
  
    handleLeavePictureInPicture() {
      this.isInPiPMode = false;
      // Check if there was a PiP video index saved
      if (this.pipVideoIndex !== null) {
    
        // Retrieve the reel and open it in fullscreen
        const reel = this.reels[this.pipVideoIndex];
        if (reel) {
          this.$router.go(-1);
          this.$nextTick(() => {
            this.openFullScreen(reel, this.pipVideoIndex);
          });
        }
    
      }
    
    },
    
    handleVideoError(index) {
      const reel = this.reels[index];
      reel.videoError = true;
      console.error(`Video failed to load: ${reel.video_url}`);
    },
  
    retryVideo(index) {
      const reel = this.reels[index];
      const videoElement = document.getElementById(`video-${reel.id}`);
      if (videoElement) {
        reel.videoError = false;
        videoElement.load(); // Reload the video source
        videoElement.play().catch(err => console.error("Retry failed:", err));
      }
    },
    
    beforeDestroy() {
    
      // Cleanup all video players
    
      Object.values(this.players).forEach((player) => {
    
        if (player && typeof player.dispose === 'function') {
    
          player.dispose();
    
        }
    
      });
    
      this.players = {};
    
      this.originalReels = [];
    
      this.fullScreenReels = [];
      document.removeEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);
    
    },
     
    enableAutoplay() {
    
      this.userInteracted = true;
    
      if (this.players[this.currentPlayingIndex]) {
    
        this.players[this.currentPlayingIndex].play();
    
        // Remove play button overlay
        const videoEl = Array.isArray(this.$refs['videoPlayer_' + this.currentPlayingIndex]) ? this.$refs['videoPlayer_' + this.currentPlayingIndex][0] : this.$refs['videoPlayer_' + this.currentPlayingIndex];
    
        if (videoEl) {
          const playButtonOverlay = videoEl.querySelector('.play-button-overlay');
          if (playButtonOverlay) {
            if (this.userInteracted) {
              playButtonOverlay.style.display = 'none';
            }
    
          }
    
        }

      }
    
    },
    

  },

  mounted() {
 
    this.checkLoginStatus(); // Check login status when the component is mounted
    this.fetchReelData().then(() => {
      this.initializeVideoJS();
      this.initializeVideoObserver();
    });
    this.initializeInfiniteScroll();
    document.addEventListener('exitPictureInPicture', this.handleLeavePictureInPicture);

  },


  setup() {
    const router = useRouter();
    const selectedRole = ref(localStorage.getItem('selectedRole') || 'influencer');
    const search_query = ref('');
    const products = ref([]);
    const filteredProducts = ref([]);
    const allProducts = ref([]);
    const brand_videos = ref([]);
    const image_files = ref([]);
    const influencer_videos = ref([]);
    const showDropdown = ref(false);
    const showDetails = ref([]);
    const currentPosition = ref(0);
    const visibleCards = ref(6);
    const hoveredVideo = ref(null);  // Define hoveredVideo
    const videoRefs = ref([]);
    const images = ref([]);
    const currentVideoId = ref('');
    const images_data = ref([]);
    const video_products = ref([])
    const heroVideo = ref(null);
    const isFullScreenMode = ref(false);
    const selectedDepartments = ref([]);
    const filter_product_list = (data) =>{
      allProducts.value = data;
      filteredProducts.value = data;
    };
    const currentIndex = ref(0);
    const isFullScreen = ref(false); // Fullscreen toggle
    const selectedVideo = ref(null);
    const currentCollection = ref([]);
    const selected_collection_type = ref('');
    const updateRole = (role) => {
      selectedRole.value = role;
      localStorage.setItem('selectedRole', role);
    };

    const onImageClick = (image_id) => {
      // Fetch products before navigating to the new route
      fetchProducts().then(() => {
        // Construct the URL for the product route
        const product_url = router.resolve({ name: 'product', params: { id: image_id } }).href;

        // Change the window location to the new URL and refresh the page
        window.location.href = product_url;
        window.location.reload(); // This will refresh the page
      });
    };
    const fetchProducts = async () => {
      try {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`;

        const response = await fetch(FETCH_PRODUCT_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        const data = await response.json();
        products.value = data;
        allProducts .value = data;
        filteredProducts.value = data; // Initialize with all products
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const filterProducts = () => {
      const query = search_query.value.toLowerCase();
      filteredProducts.value = [...allProducts.value];
      if (query) {
          const search = new RegExp(query, 'i');
          filteredProducts.value = filteredProducts.value.filter((product) => {
              const match = (
                  search.test(product.product.product_name) ||
                  search.test(product.product.brand__brand_name) ||
                  search.test(product.product.description) ||
                  search.test(product.product.category__category_name)
              );
              return match;
          });
      }
  };

    const initializeShowDetails = () => {
      showDetails.value = new Array(brand_videos.value.length).fill(false);
    };

    const toggleDetails = (index) => {
      showDetails.value[index] = !showDetails.value[index];
    };

    const toggleOverlay = (index, isHovering) => {
      const overlay = jQuery('.overlay').eq(index);
      if (!showDetails.value[index] && isHovering) {
        overlay.addClass('bg-dark');
      } else {
        overlay.removeClass('bg-dark');
      }
    };

    const updateVisibleCards = () => {
      const windowWidth = jQuery(window).width();
      if (windowWidth <= 480) {
        visibleCards.value = 1;
      } else if (windowWidth <= 768) {
        visibleCards.value = 2;
      } else if (windowWidth <= 1200) {
        visibleCards.value = 4;
      } else {
        visibleCards.value = 6;
      }
    };

    const fetchBrandVideos = async () => {
      try {
        const GET_ALL_BRAND_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_brands_videos/`;
    
        const response = await fetch(GET_ALL_BRAND_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        const data = await response.json();
    
        // If the data structure has videos nested, adjust this line:
        brand_videos.value = data.results || data; // Adjust based on actual response structure
    
        loadImages();
        if (brand_videos.value.length > 0) {
          loadImagesForVideos();
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    const loadImages = () => {
    
      if (brand_videos.value && brand_videos.value.length > 0) {
        const video_id = brand_videos.value[0].id;
    
        const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video_id}`;
    
        axios.get(VIEW_IMAGE_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
    
          image_files.value = response.data; 
        })
        .catch(error => {
          console.error('Error loading images:', error);
        });
      } else {
        console.error('No brand videos available or brand_videos is empty.');
      }
    };


    const setCurrentVideo = async (videoId) => {
      currentVideoId.value = videoId; // Set the current video ID
    
      // Ensure images are reset before loading new ones
      await loadVideoImages(videoId);
    };
  
    const loadVideoImages = async (videoId) => {
      const VIEW_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${videoId}`;
      
      try {
        const response = await fetch(VIEW_IMAGE_URL, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
    
        const data = await response.json();
        images.value = data;
    
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };
    
    
    const fetchInfluencersVideos = async () => {
      try {
        const GET_ALL_INFLUENCER_VIDEOS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/all_influencers_videos/`;
        const response = await fetch(GET_ALL_INFLUENCER_VIDEOS_URL, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
    
        const data = await response.json();
        influencer_videos.value = data;
       
      } catch (error) {
        console.error('Error fetching influencer videos:', error);
      }
    };
    
    
    const scrollCards = (direction) => {
      const maxScroll = brand_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };

    const scrollerCards = (direction) => {
      const maxScroll = influencer_videos.value.length - visibleCards.value;
      if (direction === 'right' && currentPosition.value < maxScroll) {
        currentPosition.value++;
      } else if (direction === 'left' && currentPosition.value > 0) {
        currentPosition.value--;
      }
    };
    const showPopUp = (index) => {
      this.hoveredVideo = index;
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.play().catch(error => console.error("Error playing video:", error));
      }
    };
    
    const hidePopUp = (index) => {
      this.hoveredVideo = null;
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };

    const pauseVideo = (index) => {
      const videoElement = document.querySelectorAll('video')[index];
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };
    
    const loadImagesForVideos = ()  => {
      if (!brand_videos.value || brand_videos.value.length === 0) {
        console.error('No videos available to load images for.');
        return;
      }

      // Loop through each video and fetch images for that video
      for (let video of brand_videos.value) {
        try {
          const VIEW_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/view_image/${video.id}`;
 
          axios.get(VIEW_PRODUCTS_URL, {
              headers: {
                  'Content-Type': 'application/json'
              }
          })
          .then(response => {
            video.video_products = response.data; // Add images to the video object

          })
        } catch (error) {
          console.error(`Error fetching images for video ${video.id}:`, error);
        }
      }
    };
    function getProductImages(videoId) {
      return images.value[videoId] || [];
    }
    const displayedVideos = computed(() => {
      const totalVideos = currentCollection.value.length;
  
      if (totalVideos === 0) return [];
  
      const previousIndex = currentPosition.value > 0 ? currentPosition.value - 1 : null;
      const nextIndex = currentPosition.value < totalVideos - 1 ? currentPosition.value + 1 : null;
  
      const videosWithImages = [
          previousIndex !== null
              ? {
                  ...currentCollection.value[previousIndex],
                  product_images: getProductImages(currentCollection.value[previousIndex].id),
              }
              : null,
          {
              ...currentCollection.value[currentPosition.value],
              product_images: getProductImages(currentCollection.value[currentPosition.value].id),
          },
          nextIndex !== null
              ? {
                  ...currentCollection.value[nextIndex],
                  product_images: getProductImages(currentCollection.value[nextIndex].id),
              }
              : null,
      ].filter(Boolean); // Remove null entries
  
      return videosWithImages;
  });

    const nextVideo = async () => {

      currentPosition.value = (currentPosition.value + 1) % currentCollection.value.length; // Move to the next index
      selectedVideo.value = currentCollection.value[currentPosition.value]; // Update the selected video
      await loadVideoImages(selectedVideo.value.id); // Load the images for the new video
    };
    
    const previousVideo = async () => {
      currentPosition.value = (currentPosition.value - 1 + currentCollection.value.length) % currentCollection.value.length; // Move to the previous index
      selectedVideo.value = currentCollection.value[currentPosition.value]; // Update the selected video
      await loadVideoImages(selectedVideo.value.id); // Load the images for the new video
    };
  

    // let lastScrollTop = 0;
    const scrollVideo = (event) => {
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight - event.target.clientHeight;
    
      // Find the current index in displayedVideos
      const currentIndex = displayedVideos.value.findIndex(v => v.id === selectedVideo.value.id);
    
      if (scrollTop <= 0 && currentIndex > 0) {
        // Scroll up to previous video
        currentPosition.value = (currentPosition.value - 1 + currentCollection.value.length) % currentCollection.value.length;
        selectedVideo.value = currentCollection.value[currentPosition.value];
        loadVideoImages(selectedVideo.value.id); // Ensure images are loaded
      } else if (scrollTop >= scrollHeight && currentIndex < displayedVideos.value.length - 1) {
        // Scroll down to next video
        currentPosition.value = (currentPosition.value + 1) % currentCollection.value.length;
        selectedVideo.value = currentCollection.value[currentPosition.value];
        loadVideoImages(selectedVideo.value.id); // Ensure images are loaded
      }
    };

    onMounted(() => {
      watch(
        () => influencer_videos.value[0], 
        (new_video) => {
          if (new_video && new_video.video_url) {
            nextTick(() => {
              const video_element = heroVideo.value;
      
              if (video_element) {
                // Create an IntersectionObserver instance
                const observer = new IntersectionObserver(
                  (entries) => {
                    entries.forEach(entry => {
                      if (entry.intersectionRatio > 0.5) { 
                        // If more than 50% of the video is visible
                        video_element.play().catch(error => console.error("Error playing video:", error));
                      } else {
                        video_element.pause();
                      }
                    });
                  },
                  {
                    threshold: [0.5], // Trigger callback when visibility crosses 50%
                  }
                );
      
                // Observe the video element
                observer.observe(video_element);
              } else {
                console.error("heroVideo ref is null. Ensure the video element is rendered correctly.");
              }
            });
          }
        },
        { immediate: true }
      );
      
      watch(isFullScreenMode, (newVal) => {
        if (newVal) {
          // Pause the main video when entering fullscreen mode
          if (heroVideo.value) {
            heroVideo.value.pause();
          }
        }
      });
      updateVisibleCards();
      jQuery(window).on('resize', updateVisibleCards);
      const $firstNavbar = jQuery('.first-navbar');
      const firstNavbarOffset = $firstNavbar.offset().top;
    
      jQuery(window).on('scroll', function() {
        if (jQuery(window).scrollTop() >= firstNavbarOffset) {
          $firstNavbar.addClass('position-fixed');
        } else {
          $firstNavbar.removeClass('position-fixed');
        }
      });
    
    
      fetchProducts();
      fetchBrandVideos();
      fetchInfluencersVideos();
      loadImages();
      pauseVideo();
      loadImagesForVideos();

    });

    watch(
      () => isFullScreen.value,
      (new_value) => {
        if (new_value) {
          nextTick(() => {
            const video_container = document.querySelector('.video-cards');
            if (video_container) {
              video_container.addEventListener('scroll', scrollVideo);
            } else {
              console.error(".video-cards element not found. Ensure it's rendered in the DOM.");
            }
          });
        }
      }
    );
    watch(search_query, filterProducts);
    watch(brand_videos, initializeShowDetails);

    return {
      selectedRole,
      search_query,
      products,
      filteredProducts,
      brand_videos,
      influencer_videos,
      showDropdown,
      showDetails,
      currentPosition,
      visibleCards,
      hoveredVideo,
      image_files,
      videoRefs,
      images,
      currentVideoId,
      images_data,
      video_products,
      heroVideo,
      selectedDepartments,
      currentIndex,
      selectedVideo,
      isFullScreen,
      selected_collection_type,
      displayedVideos,
      isFullScreenMode,
      updateRole,
      fetchProducts,
      filterProducts,
      scrollCards,
      showPopUp,
      hidePopUp,
      toggleDetails,
      toggleOverlay,
      setCurrentVideo,
      pauseVideo,
      loadVideoImages,
      onImageClick,
      filter_product_list,
      nextVideo,
      previousVideo,
      scrollerCards,
      scrollVideo,
      
      
    };
  },
 
};
