import $ from 'jquery';
import Swal from 'sweetalert2';

export default {
  name: 'RegisterView',
  data() {
    return {
      selectedRole: 'influencer',
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        phone_number: '',
        brand_name: '',
        agree: false,
        role: ''
      },
      errorMessage: '',
      successMessage: '',
      emailErrorMessage: '',
      passwordType: 'password',
    confirmPasswordType: 'password',
    };
  },
  mounted() {
    const names_regex = /^[a-zA-Z]+$/;

    // Validate first and last name to only allow letters
    $('#first_name, #last_name').on('keydown', function (event) {
      const keyPressed = String.fromCharCode(event.which || event.keyCode);

      if (
        event.keyCode === 8 ||
        event.keyCode === 46 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 9 ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey
      ) {
        return;
      }
      if (!names_regex.test(keyPressed)) {
        event.preventDefault();
      }
    });

    $('#password').on('input', this.updatePasswordRequirements);
  },
  created() {
    const selectedRole = localStorage.getItem('selectedRole');
    if (selectedRole) {
      this.selectedRole = selectedRole;
      this.form.role = selectedRole;
    }
  },
  methods: {
    validateForm() {
      const password_pattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#()^><])[A-Za-z\d@$!%*?&#()^><]{8,}$/;
      const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!email_pattern.test(this.form.email)) {
        this.emailErrorMessage = 'Invalid email format. Please enter a valid email address.';
        return false;
      }

      if (!password_pattern.test(this.form.password)) {
        this.errorMessage =
          'Password must be at least eight characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.';
        return false;
      }

      if (this.form.password !== this.form.confirm_password) {
        this.errorMessage = 'Passwords do not match.';
        return false;
      }

      // Clear error messages if validations pass
      this.errorMessage = '';
      this.emailErrorMessage = '';
      return true;
    },


    updatePasswordRequirements() {
      const password = $('#password').val();
      const meets_length_requirement = password.length >= 8;
      const meets_uppercase_requirement = /[A-Z]/.test(password);
      const meets_lowercase_requirement = /[a-z]/.test(password);
      const meets_digit_requirement = /\d/.test(password);
      const meets_special_char_requirement = /[@$!%*?&#()^><]/.test(password);

      $('#length-requirement').toggleClass('text-success', meets_length_requirement);
      $('#uppercase-requirement').toggleClass('text-success', meets_uppercase_requirement);
      $('#lowercase-requirement').toggleClass('text-success', meets_lowercase_requirement);
      $('#digit-requirement').toggleClass('text-success', meets_digit_requirement);
      $('#specialchar-requirement').toggleClass('text-success', meets_special_char_requirement);
    },

    async onSubmit() {
      if (!this.validateForm()) {
        return;
      }

      try {
        const REGISTER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/register/`;
        const response = await $.ajax({
          url: REGISTER_URL,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(this.form),
          dataType: 'json'
        });

        const responseData = typeof response === 'string' ? JSON.parse(response) : response;

        if (responseData.status === 'success') {
          Swal.fire({
            title: 'Success',
            text: responseData.message,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.href = '/login';
          });
          this.successMessage = responseData.message;
          this.errorMessage = '';
        } else {
          this.errorMessage = responseData.message;
          this.successMessage = '';
        }
      } catch (error) {
        if (error.status === 400 && error.responseJSON && error.responseJSON.message) {
          const errorMessage = error.responseJSON.message;

          if (errorMessage === 'User with this email already exists.') {
            this.emailErrorMessage = 'Email already exists.';
          } else {
            this.errorMessage = errorMessage;
          }
        } else {
          this.errorMessage = 'Network error. Please try again.';
        }
      }
    },
    togglePassword() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    toggleConfirmPassword() {
      this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password';
    }
  }
};
