<div class="home-navbar">
    <Influencer_sidebar />
  </div>
  <div class="app-content delivery-category">
    <Influencer_navbar />
    
    <!-- Main Content -->
    <div class="address-content mt-4 col-6">
      <h5 class="fw-bold">Banking Details</h5>
  
      <div v-if="banking_details">
        <!-- Display Existing Banking Details -->
        <div class="mb-4">
          <input
            type="text"
            class="form-control form-control-sm"
            id="account_holder"
            v-model="view_form.account_holder"
            required
          />
        </div>
  
        

        <div class="mb-4">
            <select
                class="form-control form-control-sm"
                v-model="view_form.bank_id"
                required
            >
                <option value="" disabled>Select Bank</option>
                <option 
                v-for="bank in banks" 
                :key="bank.id" 
                :value="bank.id"
                >
                {{ bank.name }}
                </option>
            </select>
          </div>
  
        <div class="mb-4">
          <input
            type="text"
            class="form-control form-control-sm"
            id="branch_code"
            v-model="view_form.branch_code"
            :readonly="true"
            required
          />
        </div>
  
        <div class="mb-4">
          <input
            type="text"
            class="form-control form-control-sm"
            id="account_number"
            v-model="view_form.account_number"
            required
          />
        </div>
  
        <div class="mb-4">
          <select
            class="form-control form-control-sm"
            id="account_type"
            v-model="view_form.account_type"
            required
          >
            <option value="" disabled>Select Account Type</option>
            <option v-for="account_type in account_types" :key="account_type.id" :value="account_type.id">
              {{ account_type.account_type }}
            </option>
          </select>
        </div>
        <button type="button" id="update_btn" class="submit-btn">Save</button>
      </div>
  
      <div v-else>
        <!-- Add Banking Details Form -->
        <form @submit.prevent="save_banking_details">
          <div class="mb-4">
            <label for="account_holder">Account Holder</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="account_holder"
              v-model="form.account_holder"
              placeholder="Enter Account Holder"
              required
            />
          </div>
  
          <div class="mb-4">
            <label for="bank_name">Bank Name</label>
            <select
              class="form-control form-control-sm"
              id="bank_name"
              v-model="form.bank_name"
              @change="update_branch_code"
              required
            >
              <option value="" disabled>Select Bank</option>
              <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{ bank.name }}</option>
            </select>
          </div>
  
          <div class="mb-4">
            <label for="branch_code">Branch Code</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="branch_code"
              v-model="form.branch_code"
              placeholder="Branch Code"
              required
              disabled
            />
          </div>
  
          <div class="mb-4">
            <label for="account_number">Account Number</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="account_number"
              v-model="form.account_number"
              placeholder="Enter Account Number"
              required
            />
          </div>
  
          <div class="mb-4">
            <label for="account_type">Account Type</label>
            <select
            class="form-control form-control-sm"
            id="account_type"
            v-model="view_form.account_type"
            required
          >
            <option value="" disabled>Select Account Type</option>
            <option 
              v-for="account_type in account_types" 
              :key="account_type.id" 
              :value="account_type.id"
            >
              {{ account_type.account_type }}
            </option>
          </select>
          </div>
  
          <button class="submit-btn" id="save_btn">Save</button>
        </form>
      </div>
    </div>
  </div>