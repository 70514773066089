import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue'
import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue'
import jQuery from 'jquery';
import $ from 'jquery';
import Swal from 'sweetalert2';
import EventBus from '@/eventBus.js';


export default {
  components: {
    Influencer_navbar,
    Influencer_sidebar
  },
  props: {
    search_input: {
      required: false,
      type: String
    }
  },
  data() {
    return { 
      activeTab: 'tag',
      cartQuantity: 0, 
      cartTotal: 0, 
      isCartOpen: false, 
      cartItems: JSON.parse(localStorage.getItem('cart')) || [], 
      products: [],  // Initialize as an empty array
      search_query: '', // Initialize search_query for filter
      selectedDepartment: null,
      selectedCategory: null,
      selectedSubcategories: [],
      showDropdown: false,
      allProducts: [],
      showBrandDropdown: false,
      showProductDropdown: false,
      tagInput: '',
      searchQuery: '',
      filteredBrands: [],
      filteredProducts: [],
      filtered_tagged_products: [],
      brands: [],   
      selectedProductIds: [],
      selectedProducts: [],
      brandError: false,
      showCaptionError: false,
      departments: [],
      sidebarOpen: false,
      subSidebarOpen: [],
      isRightSidebarOpen: false,
      imageUploaded: false,
      uploadedProducts: [],
      current_image: {
        id: null,
        description: '',
        price: '',
        no_of_items: '',
        categories: [],
        selectedCategory: '',
        newCategory: '',
        size:'',
        color:'',
        quantity:'',
        product_name:'',
        image_urls: ''
      }
    };
  },

  created() {
    this.fetchProducts();
    this.fetchCategories();
    this.fetchCartDetails();
    this.clearCartLocalStorage();
    EventBus.on('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

    EventBus.on('toggle-right-sidebar', () => {
      this.fetchCartDetails();
      this.isRightSidebarOpen = true;
    });
    EventBus.on('update-cart', () => {
      this.fetchCartDetails();
      this.isRightSidebarOpen = true;

    });
  },
  
  watch: {
    search_query: 'filterProducts'
  },
  selectedDepartment() {
    this.updateCategories(); // Update categories based on the department change
  },
  selectedCategory() {
    this.updateSubcategories(); // Update subcategories based on the category change
  },

  beforeDestroy() {
    EventBus.off('cart-updated', (quantity) => {
      this.cartQuantity = quantity;
    });

  },

  mounted() {
    const $searchIcon = jQuery('#searchIcon');
    const $searchInput = jQuery('#search_input');
    const $sidebar_close = jQuery('.close-sidebar');
    const $sidebar = jQuery('.sidebar');
    this.fetchBrands();
    this.fetchTaggedProducts();
    this.handleClickOutside();
    
    // Function to close sidebar on screens 767px or smaller
    function handleSidebarOnResize() {
      if (window.innerWidth <= 767) {
        $sidebar.addClass('close');   
      } else {
        $sidebar.removeClass('close');  
      }
    }

    // Check screen size on page load
    handleSidebarOnResize();  
    $searchIcon.on('click', function(event) {
      event.stopPropagation();
      $searchInput.toggleClass('d-none'); 
      $searchIcon.toggleClass('bx-search bx-x');  
    });

    $sidebar_close.on('click', function(event) {
      event.stopPropagation();
      $sidebar.toggleClass('close');   
    });

    // Add event listener for window resize to handle dynamic resizing
    jQuery(window).on('resize', function() {
      handleSidebarOnResize(); 
    });
  },

  methods: {

    formatPrice(price) {
      const numericPrice = parseFloat(price);
      if (isNaN(numericPrice)) {
        return 'R 0.00';
      }
      return `${numericPrice.toLocaleString('en-ZA', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },


    switchTab(tab) {
      this.activeTab = tab;
    },

    updateCategories() {   
      const selectedDept = this.departments.find(dept => dept.id === this.selectedDepartment);
      if (selectedDept) {
        this.categories = selectedDept.categories || [];
        this.selectedCategory = this.current_image.category_id || null;
        if (!this.categories.some(cat => cat.id === this.selectedCategory)) {
          this.selectedCategory = null; // Reset if not in the list
          this.subcategories = [];
          this.selectedSubcategories = [];
        } else {
          this.updateSubcategories(); // Populate subcategories
        }
      }
    },
    updateSubcategories() {
      const selectedCat = this.categories.find(cat => cat.id === this.selectedCategory);
      this.subcategories = selectedCat ? selectedCat.subcategories : [];
      // Retain the previously selected subcategory
      if (this.selectedSubcategories && !this.subcategories.some(sub => sub.id === this.selectedSubcategories)) {
          this.selectedSubcategories = null;
      }
    },

    uploadImage() {
      const product_name = document.getElementById('product_name').value;
      const description = document.getElementById('description').value;
      const no_of_items = document.getElementById('no_of_items').value;
      const price = document.getElementById('price').value;
      const color = document.getElementById('color').value;
      const imageFileInput = document.getElementById('image_url');
      const imageFile = imageFileInput.files[0];
      const imagePreviewUrl = URL.createObjectURL(imageFile);

    
      const sizes = [];
      const quantities = [];
      const sizeTable = document.getElementById('size_table');
      const sizeRows = sizeTable.getElementsByTagName('tr');
    
      for (let i = 0; i < sizeRows.length; i++) {
        const sizeInput = sizeRows[i].querySelector('#size');
        const quantityInput = sizeRows[i].querySelector('#size_quantity');
        if (sizeInput && quantityInput) {
          sizes.push(sizeInput.value);
          quantities.push(quantityInput.value);
        }
      }
    
      this.uploadedProducts.push({
        product_name,
        description,
        no_of_items,
        price,
        color,
        imageFile,
        imagePreviewUrl, // Store the preview URL for display
        sizes,
        quantities,
        department: this.selectedDepartment,
        category: this.selectedCategory,
        sub_category: this.selectedSubcategories
      });
    
      // Reset the form and variables
      document.getElementById('uploadForm').reset();
      this.selectedDepartment = null;
      this.selectedCategory = null;
      this.selectedSubcategories = null;
      this.imageUploaded = false;
      document.getElementById('imagePreview').src = '';
    },

    goToLandingPage() {
      this.$router.push('/'); 
    },

    previewVideo(event) {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        document.getElementById('videoPreview').src = url;
        document.getElementById('videoPreview').style.display = 'block';
      }
    },

    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        document.getElementById('imagePreview').src = url;
        document.getElementById('imagePreview').style.display = 'block';
        this.imageUploaded = true;
      }
    },  
    
    toggleRightSidebar() {
      this.fetchCartDetails();
      this.isRightSidebarOpen = !this.isRightSidebarOpen;
    },

    update_search(event) {
      this.$emit('update:search_input', event.target.value);
      this.search_query = event.target.value;
      this.filterProducts();
        },

      async fetchProducts() {
        const FETCH_PRODUCT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/fetch_product_api/`; 
        try {
          const response = await fetch(FETCH_PRODUCT_URL, {
            headers: {
              'Content-Type': 'application/json'
            },
          });
          const data = await response.json();
          this.products = data;           // Use this.products directly
          this.allProducts = data;        // Use this.allProducts directly
          this.filteredProducts = data; // Initialize with all products
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      },

      clearCartLocalStorage() {
        this.setLocalStorage('cart', null);
      },
      
      getLocalStorage(name) {
        const value = localStorage.getItem(name);
        return value ? JSON.parse(value) : null;
      },
      
      setLocalStorage(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
      },

    increaseQuantity(item) {
      item.quantity += 1;
      this.updateCartInDatabase(item.product_id,1);
    },
  
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity -= 1;
        this.updateCartInDatabase(item.product_id,-1);
      }
    },
    filterProducts() {
      const query = this.search_query.toLowerCase();
      // Start with all products
      this.filteredProducts = [...this.allProducts];

      // Apply search filter
      if (query) {
          const search = new RegExp(query, 'i');
          this.filteredProducts= this.filteredProducts.filter((product) => {
              const match = (
                  search.test(product.product.product_name) ||
                  search.test(product.product.brand__brand_name) ||
                  search.test(product.product.description) ||
                  search.test(product.product.category__category_name)
              );
              return match;
          });
      }
  },

    
    async updateCartInDatabase(productId, quantity) {
      const token = localStorage.getItem('token');
      const UPDATE_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/add_to_cart/`;
      try {
          const response = await $.ajax({
              url: UPDATE_CART_URL,
              method: 'POST',
              headers: {
                  'Authorization': `Token ${token}`,
                  'Content-Type': 'application/json'
              },
              data: JSON.stringify({
                  product_id: productId,
                  quantity: quantity
              }),
              processData: false,
          });  
          if (response.message === 'success') {
              this.fetchCartDetails();
          } else {
              console.error('Failed to update cart in the database:', response.error || 'Unknown error');
          }
      } catch (error) {
          console.error('Error updating cart in the database:', error);
      }
  },

  async deleteCartItem(productId) {
    const token = localStorage.getItem('token');
    const DELETE_CART_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/delete/${productId}/`;
  
    try {
        const response = await $.ajax({
          url: DELETE_CART_URL,
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.message === 'success') {
          this.fetchCartDetails();
        } else {
          console.error('Error deleting item from the cart:', response.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error deleting cart item:', error);
      }
    },  
  
    async fetchCartDetails() {
      const token = localStorage.getItem('token');
  
      if (token) {
        // User is logged in, fetch the cart from the server
        const CART_API_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/customer_management/get_cart_details/`;
        try {
          const response = await $.ajax({
            url: CART_API_URL,
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json',
            },
          });
    
          if (response) {
            if (response.cart_products.length > 0) {
            this.cartItems = response.cart_products;
                
            this.cartQuantity = response.total_quantity;
            this.cartTotal = parseFloat(response.total_price);
          } else {
              this.cartProducts = [];
              this.closeCart();
          }
          }
          EventBus.emit('cart-updated', this.cartQuantity);

        } catch (error) {
          console.error('Failed to fetch cart from the server:', error);
    
          const cart = this.getLocalStorage('cart') || [];
    
          this.cartItems = cart;
          this.cartQuantity = cart.reduce((total, item) => total + item.quantity, 0);
          this.cartTotal = cart.reduce((total, item) => total + (item.quantity * item.price), 0);
        }
      }
    },
    async logout() {
      const token = localStorage.getItem('token');
      const LOGOUT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/logout/`;

      const response = await $.ajax({
        url: LOGOUT_URL,
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (response) {
        localStorage.removeItem('token'); // Corrected: This should be 'token', not 'localStorage.removeItem(token)'
        if (this.$route.path === '/') {
          // If on the homepage, reload the page to reflect the logged-out state
          window.location.reload();
        } else {
          // If on another page (e.g., profile), redirect to the homepage
          this.$router.push('/');
        }
      } else {
        throw new Error('Logout failed');
      }
    },

    async fetchCategories() {
      const FETCH_CATEGORIES_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/all_categories/`;

      try {
        const response = await $.ajax({
          url: FETCH_CATEGORIES_URL,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        this.departments = response;
        // Initialize subSidebarOpen with false values for each department
        this.subSidebarOpen = Array(this.departments.length).fill(false);
      } catch (error) {
        console.error(error);
      }
    },

    handleUpload() {
      if (this.activeTab === 'tag') {
        this.uploadVideo();
      } else {
        this.uploadProductVideo();
      }
    },
    
    uploadVideo() {

      if (!$('#video_url')[0].files[0]) {
        Swal.fire({
          icon: 'error',
          title: 'No Video Selected',
          text: 'Please select a video to upload.',
        });
        return;
      }
      
        this.brandError = false;
        this.showCaptionError = false;

        if (!this.selectedBrandId && !this.showBrandDropdown) {
          this.brandError = true;
        }

        if (!this.caption) {
          this.showCaptionError = true;
        }
        if (this.brandError || this.showCaptionError) {
          return;
        }
      Swal.fire({
        title: 'Uploading Video',
        text: 'Please wait while we upload your video...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    
      const formData = new FormData();
      formData.append('caption', $('#caption').val());
      formData.append("brand_id", this.selectedBrandId);
      this.selectedProductIds.forEach(id => {
        formData.append('products', id);
      });
      formData.append('video_url', $('#video_url')[0].files[0]);

      const token = localStorage.getItem('token');
      const UPLOAD_CONTENT_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/upload_content/`;
      fetch(UPLOAD_CONTENT_URL, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
        if (response.statusText == 'Created') {
          Swal.fire({
            icon: 'success',
            title: 'Upload Successful',
            text: 'Your video has been uploaded successfully!',
          }).then(() => {
            window.location.reload();
          });
        } else {
          throw new Error('Upload failed');
        }
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Upload Failed',
          text: 'Failed to upload video. Please try again.',
        });
      });
    },
    
    uploadProductVideo() {

      if (!$('#video_url')[0].files[0]) {
        Swal.fire({
          icon: 'error',
          title: 'No Video Selected',
          text: 'Please select a video to upload.',
        });
        return;
      }

      this.showCaptionError = false;

      if (!this.caption) {
        this.showCaptionError = true;
      }
    
      Swal.fire({
        title: 'Uploading Video',
        text: 'Please wait while we upload your video...',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
    
      const token = localStorage.getItem('token');
      const UPLOAD_VIDEO_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/upload_influencer_content/`;
    
      const videoFormData = new FormData();
      videoFormData.append('video_url', $('#video_url')[0].files[0]);
      videoFormData.append('caption', $('#caption').val());
    
      fetch(UPLOAD_VIDEO_URL, {
        method: 'POST',
        body: videoFormData,
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        const videoId = data.video_id;
        Swal.fire({
          icon: 'success',
          title: 'Video Upload Successful',
          text: 'Your video has been uploaded successfully!',
        });
    
        const uploadedProducts = this.uploadedProducts;
        const UPLOAD_IMAGE_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/influencer_products/`;
    
        uploadedProducts.forEach(product => {
          const formData = new FormData();
    
          formData.append('image_urls', product.imageFile);
          formData.append('description', product.description);
          formData.append('product_name', product.product_name);
          formData.append('no_of_items', product.no_of_items);
          formData.append('department', product.department);
          formData.append('category', product.category);
          formData.append('sub_category', product.sub_category);
    
          product.sizes.forEach(size => {
            formData.append('sizes[]', size);
          });

          product.quantities.forEach(quantity => {
            formData.append('quantities[]', quantity);
          });
    
          formData.append('color', product.color);
          formData.append('price', product.price);
          formData.append('video_id', videoId);
    
          fetch(UPLOAD_IMAGE_URL, {
            method: 'POST',
            body: formData,
            headers: {
              'Authorization': `Token ${token}`
            }
          })
          .then(response => {
            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'Upload Successful',
                text: 'Your product has been uploaded successfully!',
              }).then(() => {
                window.location.reload();
              });
            } else {
              throw new Error('Upload failed');
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Product Upload Failed',
              text: 'Failed to upload product. Please check product details and try again.',
            });
          });
        });
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Video Upload Failed',
          text: 'Failed to upload video. Please check video file and try again.',
        });
      });
    },

    async fetchBrands() {
      const token = localStorage.getItem('token');
      const GET_ALL_BRANDS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_brands/`;
    
      try {
        const response = await fetch(GET_ALL_BRANDS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        this.brands = data;
      } catch (error) {
        console.error('Failed to fetch brands:', error);
      }
    },
    
  filterBrands() {
    if (this.tagInput.startsWith('@')) {
      this.showBrandDropdown = true;
      const query = this.tagInput.slice(1).toLowerCase();
      this.filteredBrands = this.brands.filter(brand => 
        brand.brand_name.toLowerCase().includes(query)
      );

      this.showBrandDropdown = this.filteredBrands.length > 0;
    } else {
      this.showBrandDropdown = false;
    }
  },
  
  selectBrand(brand) {
    this.tagInput = `@${brand.brand_name}`;
    this.selectedBrandId = brand.id;
    this.showBrandDropdown = false;
    this.brandError = false;
    this.fetchTaggedProducts();
  },

  async fetchTaggedProducts() {
    const token = localStorage.getItem('token');
    const GET_ALL_PRODUCTS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_products/`;

    try {
      const response = await fetch(GET_ALL_PRODUCTS_URL, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      const data = await response.json();
      const filteredProducts = data.filter(product => product.product.brand_id === this.selectedBrandId);
      const uniqueProducts = [...new Map(filteredProducts.map(item => [item.product_id, item])).values()];
      this.products = uniqueProducts;
      
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  },
  filterTaggedProducts() {
    const query = this.searchQuery.toLowerCase(); // Convert query to lowercase for case-insensitive search
    if (query) {
      this.filtered_tagged_products = this.products.filter(product =>
        product.product.product_name.toLowerCase().includes(query)
      );
      this.showProductDropdown = this.filtered_tagged_products.length > 0;
    } else {
      this.showProductDropdown = false;
    }
  },
  selectProduct(product) {

    if (!this.selectedProductIds) {
      this.selectedProductIds = [];
    }
  
    const isProductSelected = this.selectedProductIds.some(id => id === product.id);
  
    if (!isProductSelected) {
      this.selectedProductIds.push(product.product.id);
      this.selectedProducts.push(product);
    }
  
    this.searchQuery = '';
    this.showProductDropdown = false;
  },

  removeProduct(product) {
    this.selectedProducts = this.selectedProducts.filter(p => p !== product);
  },

  removeUploadedProduct(product) {
    this.uploadedProducts = this.uploadedProducts.filter(p => p !== product);
  },
  handleClickOutside(event) {
    const dropdown = this.$refs.dropdownContainer;
    if (dropdown && !dropdown.contains(event.target)) {
      this.showProductDropdown = false;
    }
  },
  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  },

  toggleSubSidebar(departmentIndex) {
    // Toggle the open state of the clicked department's sub-sidebar
    this.subSidebarOpen[departmentIndex] = !this.subSidebarOpen[departmentIndex];
  },
    
  }
}
