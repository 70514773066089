/* global $ */
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'

export default {
  name: 'BrandsView',
  components: {
    Admin_sidebar,
    Admin_navbar
  },

  data: () => ({ items: []
}), 

  mounted() {
    this.fetchBrands();
  },


  methods: {

    async fetchBrands() {
      const FETCH_BRANDS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/brand_management/get_all_brands/`;
      try {
        const response = await fetch(FETCH_BRANDS_URL, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.items = data;  // Update component data
        this.initializeDataTable();
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    },

    loginAsBrand(brand_id) {
        this.$router.push({
          name: 'dashboard',
          query: {
            impersonate: true,
            brand_id: brand_id.toString()
          }
        });
        // Add this to store impersonation context
        localStorage.setItem('impersonate', true);
        localStorage.setItem('brand_id', brand_id.toString());
      },

    initializeDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#brands_table')) {
          $('#brands_table').DataTable().destroy();
        }
        this.dataTable = $('#brands_table').DataTable({
          pageLength: 10,
          lengthChange: false,
          searching: true,
          ordering: true,
          paging: true,
          info: true,
          autoWidth: false,
        });
      });
    },
  }
}