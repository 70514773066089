/* global $ */
import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'

export default {
    name: 'InfluencersView',
  components: {
    Admin_sidebar,
    Admin_navbar
  },

  data: () => ({ items: []
   }),


  methods: {

    async fetchInfluencers() {
        const FETCH_INFLUENCER_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/influencer_management/get_influencer_api/`;
        try {
          const response = await fetch(FETCH_INFLUENCER_URL, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          this.items = data;  // Update component data
          this.initializeDataTable();
        } catch (error) {
          console.error('Error fetching influencers:', error);
        }
      },

      initializeDataTable() {
        this.$nextTick(() => {
          if ($.fn.DataTable.isDataTable('#influencer_table')) {
            $('#influencer_table').DataTable().destroy();
          }
          this.dataTable = $('#influencer_table').DataTable({
            pageLength: 10,
            lengthChange: false,
            searching: true,
            ordering: true,
            paging: true,
            info: true,
            autoWidth: false,
            
          });
        });
      },
  }
}