import Admin_sidebar from '@/components/Admin/Admin_sidebar/AdminView.vue';
import Admin_navbar from '@/components/Admin/Admin_Navbar/AdminNavView.vue'

export default {
  components: {
    Admin_sidebar,
    Admin_navbar
  },


  
}