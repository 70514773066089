<div v-if="UserLoggedIn">
  <Influencer_navbar v-model:search_input="search_query" />
</div>
<div v-else>
  <Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />
</div>

<div class="container mt-4">
  <div class="row custom-gap">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="col-12 col-md-6"
    >
      <div class="card border-0">
        <div class="profile-info d-flex align-items-center mb-2">
          <img
          v-if="item.image"
          :src="item.image"
          class="rounded-circle me-2 profile-pic"
          alt="Profile"
        />
        <img
          v-else
          src="@/assets/images/img-error.png"
          class="rounded-circle me-2 profile-pic"
          alt="Profile"
        />
          <div class="profile-details">
            <RouterLink
              :to="{  name: 'view_Influencer_Profile', params: { influencerId: item.user.id } }"
              class="text-decoration-none text-dark"
            >
              <h6 class="mb-0">{{ item.user.first_name }} {{ item.user.last_name }}</h6>
            </RouterLink>
          </div>
        </div>

        <!-- Video section -->
        <div class="influencers-section row g-3">
          <div v-for="(video, videoIndex) in item.videos" :key="videoIndex" class="col-12 col-md-4">
            <div ref="videoPlayer_{{ videoIndex }}">

              <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="handleThumbnailError"/>
                                        
                <!-- Show Video if thumbnail_url does not exist -->
                <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100" >
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
              <div class="circle-images d-flex justify-content-center ms-1">
                <div v-for="(image, index) in video.products" :key="index" class="text-center">

                  <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                    <input type="hidden" ref="image_id" :value="image.product.id">
              
                    <img 
                      v-if="image.product && image.product.image_url" 
                      :src="image.product.image_url" 
                      class="circle-image" 
                      @error="(event) => event.target.src = require('@/assets/images/img-error.png')" 
                      alt="Product Image"
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>