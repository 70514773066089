<div class="side-panel left"></div>
<div class="side-panel right"></div>

<div class="app h-100-vh">
  <div class="container">
      <!-- Role Selection Links -->
      <div class="row justify-content-center mb-4">
          <div class="col-md-4">
              <a href="#" class="btn btn-link" @click.prevent="setRoleAndRedirect('brand')">Brand</a>
          </div>
          <div class="col-md-4 text-center">
              <a href="#" class="btn btn-link" @click.prevent="setRoleAndRedirect('influencer')">Influencer</a>
          </div>
          <div class="col-md-4 text-center">
              <a href="#" class="btn btn-link" @click.prevent="setRoleAndRedirect('customer')">Customer</a>
          </div>
      </div>

      <!-- Alerts for error or success messages -->
      <div class="row">
          <div class="col">
              <div id="errorAlert" class="alert alert-danger d-none" role="alert">{{ errorMessage }}</div>
              <div id="successAlert" class="alert alert-success d-none" role="alert">{{ successMessage }}</div>
              
              <!-- Logo -->
              <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class="kyosk-logo mb-5" >

              <!-- Registration Form -->
              <form id="register_form" class="register-form" @submit.prevent="onSubmit">

                  <!-- First and Last Name -->
                  <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="first_name">First name:</label>
                            <input type="text" id="first_name" v-model="form.first_name" class="form-control" required>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="last_name">Last name:</label>
                            <input type="text" id="last_name" v-model="form.last_name" class="form-control" required>
                        </div>
                    </div>
                </div>
                
                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="email">Email Address:</label>
                              <input type="email" id="email" v-model="form.email"  class="form-control" required>
                              <small v-if="emailErrorMessage" class="text-danger">{{ emailErrorMessage }}</small>
                          </div>
                      </div>
                      <div v-if="selectedRole === 'brand'" class="col-md-6">
                        <div class="form-group">
                          <label for="brand_name">Brand Name:</label>
                          <input type="text" id="brand_name" v-model="form.brand_name"  class="form-control" required>
                        </div>
                      </div>
                      <div v-if="selectedRole === 'influencer' || selectedRole === 'customer'" class="col-md-6">
                        <div class="form-group">
                          <label for="phone_number">Phone Number:</label>
                          <input type="tel" id="phone_number" v-model="form.phone_number" class="form-control" required>
                        </div>
                      </div>
                </div>

                  

            <!-- Password and Confirm Password -->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="passwordInput">Password:</label>
                    <div class="input-group">
                        <input :type="passwordType" id="password" v-model="form.password" class="form-control" required>
                        <button class="btn btn-outline-dark text-black" type="button" @click="togglePassword()">
                            <i :class="passwordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" id="togglePassword"></i>
                        </button>
                    </div>
                    <small class="text-danger" v-if="errorMessage && errorMessage.includes('Password')">{{ errorMessage }}</small>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="confirm_password">Confirm Password:</label>
                    <div class="input-group">
                        <input 
                            :type="confirmPasswordType" id="confirm_password" v-model="form.confirm_password" class="form-control" required>
                        <button class="btn btn-outline-dark text-black" type="button" @click="toggleConfirmPassword()">
                            <i :class="confirmPasswordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" id="toggleConfirmPassword"></i>
                        </button>
                    </div>
                    <small class="text-danger" v-if="errorMessage && errorMessage.includes('Passwords do not match')">{{ errorMessage }}</small>

                    <small id="password-requirements" class="d-none">
                        Password must be at least 8 characters long and contain:<br>
                        <span id="length-requirement"> At least 8 characters</span><br>
                        <span id="uppercase-requirement"> At least one uppercase letter</span><br>
                        <span id="lowercase-requirement"> At least one lowercase letter</span><br>
                        <span id="digit-requirement"> At least one digit</span><br>
                        <span id="specialchar-requirement"> At least one special character</span><br>
                    </small>
                </div>
            </div>
        </div>

                  <!-- Agree to Terms Checkbox -->
                  <div class="form-group">
                      <div class="form-check">
                          <input type="checkbox" id="agree" class="form-check-input" required>
                          <label for="agree" class="form-check-label">
                              Creating an account means you're okay with our Terms of Service, Privacy Policy, and our default Notification Settings.
                          </label>
                      </div>
                  </div>

                  <!-- Submit Button -->
                  <button type="submit" id="register" class="register-button">Sign Up</button>
              </form>

              <!-- Login Link -->
              <p class="mt-3">
                  Already a member? <a href="/login">Login</a>
              </p>
          </div>
      </div>
  </div>
</div>