import Influencer_sidebar from '@/components/Influencer/Influencer_Sidebar/InfluencerSidebarView.vue';
import Influencer_navbar from '@/components/Influencer/Influencer_Navbar/InfluencerNavbarView.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default {
  components: {
    Influencer_sidebar,
    Influencer_navbar,
  },
  data: () => ({
    form: { account_holder: '', bank_name: '', branch_code: '', account_number: '', account_type: '' },
    view_form: { account_holder: '', bank__name: '', bank__branch_code: '', account_number: '', account_type__account_type: '' },
    banks: [],
    account_types: [],
    banking_details: null,
    isEditing: false,
    selectedBankName: '',
    showDropdown: false, // Dropdown visibility toggle
 

  }),

  methods: {
    async fetch_banks() {
      const BANKS_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banks/`;

      try {
        const response = await fetch(BANKS_API);

        if (!response.ok) {
          console.error('Failed to fetch banks, status:', response.status);
          throw new Error('Failed to fetch banks');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          this.banks = data;
        } else {
          console.error("Expected an array of banks, got:", data);
        }
      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    },

    async fetch_account_types() {
      const ACCOUNT_TYPES_API = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_account_types/`;

      try {
        const response = await fetch(ACCOUNT_TYPES_API);

        if (!response.ok) {
          console.error('Failed to fetch account types, status:', response.status);
          throw new Error('Failed to fetch account types');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          this.account_types = data;
        } else {
          console.error("Expected an array of types, got:", data);
        }
      } catch (error) {
        console.error('Error fetching types:', error);
      }
    },

    update_branch_code() {
      const selected_bank = this.banks.find(bank => bank.id === this.form.bank_name);

      if (selected_bank) {
        this.form.branch_code = selected_bank.branch_code;
      } else {
        this.form.branch_code = '';
      }
    },

    async fetch_banking_details() {
      const BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/get_banking_details/`;
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(BANKING_DETAILS_URL, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        if (!response.ok) {
          if (response.status === 404) {
            // Banking details not found, allow user to enter them
            this.banking_details = null;
          } else {
            console.error('Failed to fetch banking details, status:', response.status);
            throw new Error('Failed to fetch banking details');
          }
        } else {
          const data = await response.json();
          this.view_form = {
            account_holder: data.account_holder,
            bank_id: this.banks.find(
                bank => bank.name === data.bank__name // Changed 'name.bank' to 'bank.name'
              )?.id || '',
            branch_code: data.bank__branch_code,
            account_number: data.account_number,
            account_type: this.account_types.find(
                type => type.account_type === data.account_type__account_type
              )?.id || ''
        
          };
          this.banking_details = data;
          this.isEditing = false;
          
        }
      } catch (error) {
        console.error('Error fetching banking details:', error);
      }
    },

    updateSelectedBankName() {
        const selectedBank = this.banks.find(bank => bank.id === this.view_form.bank_name);
        this.selectedBankName = selectedBank ? selectedBank.name : '';
      },

    async save_banking_details() {
      const token = localStorage.getItem('token');
      const BANKING_DETAILS_URL = `${process.env.VUE_APP_API_BASE_URL}/kyosk_api/system_management/add_banking_details/`;

      if (!this.form.account_holder || !this.form.bank_name || !this.form.branch_code || !this.form.account_number || !this.form.account_type) {
        Swal.fire('Error', 'All fields are required.', 'error');
        return;
      }

      const formData = {
        bank_id: this.form.bank_name,
        account_holder: this.form.account_holder,
        branch_code: this.form.branch_code,
        account_number: this.form.account_number,
        account_type_id: this.form.account_type
      };

      try {
        const response = await $.ajax({
          url: BANKING_DETAILS_URL,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          data: JSON.stringify(formData),
        });

        if (response.status === 'success') {
          Swal.fire('Success', response.message, 'success');
          this.banking_details = formData;
          this.isEditing = false;
        } else {
          Swal.fire('Error', response.message, 'error');
        }
      } catch (error) {
        console.error('Error:', error);
        Swal.fire('Error', 'An error occurred while saving your banking details.', 'error');
      }
    },

    enableEditing() {
      this.isEditing = true;
    },

    resetForm() {
      this.form = {
        account_holder: '',
        bank_name: '',
        branch_code: '',
        account_number: '',
        account_type: ''
      };
    },

    getBankName(bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return bank ? bank.name : '';
    },

    getAccountTypeName(accountTypeId) {
      const accountType = this.account_types.find(type => type.id === accountTypeId);
      return accountType ? accountType.account_type : '';
    },
  },

  mounted() {
    this.fetch_banks();
    this.fetch_account_types();
    this.fetch_banking_details();
  }
};