<div v-if="UserLoggedIn">
    <Influencer_navbar v-model:search_input="search_query" />
  </div>
  <div v-else>
    <Home_navbar @role-selected="updateRole" v-model:search_input="search_query" />
  </div>
  <div v-if="addedToWishlist" class="centered-popup alert-success">
    Successfully added to wishlist!
    <a href="/wishlist" class="alert-link"> WISHLIST</a>

  </div>
  
<div v-if="selectedRole === 'brand'" class="container mt-10">
    <div class="row">
        <div class="col-md-6">
            <h1 class="font-weight-bold text-start mb-5">
                Innovate Your Style. <br />
            Showcase your brand's <br />
            identity and craftsmanship.</h1>
            <p class="text-start mb-5 home-font">
            Present your clothing line to customers with a focus on <br />
            quality and unique designs that cater to diverse styles and occasions.
            <br />
            Making it easy for customers to discover and purchase your latest <br />
            collections. Elevate your presence in the market and connect with <br />
            fashion enthusiasts looking to express their personal style!
            </p> 
            </div> 
        <!-- Right side: Image -->
        <div class="col-md-6 d-flex align-items-center justify-content-center position-relative">
            <img :src="require('@/assets/images/brand-homepg.png')"  class="brand-homepg">
        </div>
    </div>
  </div>

<div v-else>
    <div v-if="search_query == ''">
        <div v-if="brand_videos.length === 0" class="text-center    ">
            <div class="hero">
                <div class="video-container  border-0">
                <div class="fade-overlay"></div>
                    <video :src="require('@/assets/images/default_video.mp4')" autoplay controls class="h-100">
                        
                    </video>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <div class="hero-content text-start text-light">
                            <div class="d-flex align-content-center">
                                <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo ">
                                <h3 class="ms-2 mt-n1">Khiosk </h3>
                            </div>  
                            <p></p>
                          </div>
                    </div> 
                </div>            
            </div>
        </div>
        <div v-else>
            <!-- Check if there are influencer videos -->
            <div v-if="influencer_videos.length > 0">
                <div class="hero">
                    <div class="video-container border-0">
                        <div class="fade-overlay"></div>
                        <video  ref="heroVideo" autoplay  loop playsinline controls>
                            <source :src="influencer_videos[0].video_url" type="video/mp4" />
                        </video>
                        <div v-if="userInteracted" class="play-button-overlay" @click="enableAutoplay">
                            <i class="bx bx-play-circle"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="hero-content text-start text-light">
                                <div class="d-flex align-content-center">
                                    <img :src="require('@/assets/images/orange-icon-logo.png')" alt="Kyosk Logo" class="hero-logo">
                                    <h3 class="ms-2 mt-n1">Khiosk</h3>
                                </div>
                                <p>{{ influencer_videos[0].caption }}</p> <!-- Display the video caption -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="hero-cards hero-product">
                                <div class="bg-transparent">
                                    <div class="scroller-container">
                                        <div class="sub-scroller" ref="scroller">
                                            <div class="logo-description-container">
                                                <div class="justify-content-end">
                                                    <!-- Check if the video has associated products -->
                                                    <div v-if="influencer_videos[0].products && influencer_videos[0].products.length">
                                                        <router-link v-for="(productObj, index) in influencer_videos[0].products" :key="index" :to="{ name: 'product', params: { id: productObj.product_details.id } }" class="card h-100">
                                                            <img :src="productObj.product.image_url" class="rounded-12 p-1 h-130 mw-90 card-img-top" @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                                                            <div class="card-body text-centre">
                                                                <p class="card-text description text-white">{{ productObj.product.product_name }}</p>  
                                                                <p class="card-text description text-white">by {{ productObj.product.brand__brand_name}}</p>                                                                
                                                              
                                                                <!-- Check if product details exist, and display relevant details -->
                                                                <div v-if="productObj.product_details">
                                                                    <h5 class="card-title text-warning fw-bold">R{{ productObj.product_details.price }}</h5>
                                                                </div>
                                                                <div v-else>
                                                                    <p class="text-white">Details not available</p>
                                                                </div>
                                                            </div>
                                                        </router-link>
                                                    </div>
                                                    <!-- If there are no products, display a message -->
                                                    <div v-else>
                                                        <p class="text-white">No products available for this video.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        
                <br>   
                

        <!-- Influencer Colection SECTION-->
        <h3 class="mt-4 fw-bold">Video Collection</h3>
        <div class="px-5 d-flow-root subvideo-content mb-5">
          <div class="scroller-container">
            <div class="scroller">
              <div class="card-container" :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">               
                  <div v-for="(video, index) in influencer_videos" :key="index" class="card">
                    
                    <div class="product_modal">
                      <div class="view-product-list">
                        <!-- Thumbnail or Video -->
                        <div @click="openFullScreen(video.id, index)">
                          <!-- Show Thumbnail if thumbnail_url exists -->
                          <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="handleThumbnailError" />
                          
                          <!-- Show Video if thumbnail_url does not exist -->
                          <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100">
                            <source :src="video.video_url" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </div>
        
                        <!-- Product Images -->
                        <div class="circle-images pb-85">
                          <router-link v-for="(product, imgIndex) in video.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }">
                            <img :src="product.product.image_url" class="circle-image" @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="text-start mt-2 ms-1">
                      <h6 class="fw-bold">{{ video.caption }}</h6>
                    </div>
                  </div>
              </div>
            </div>
            <!-- Scroll Buttons -->
            <button class="scroll-btn left" @click="scrollerCards('left')"><i class='bx bxs-chevron-left'></i></button>
            <button class="scroll-btn right" @click="scrollerCards('right')"><i class='bx bxs-chevron-right'></i></button>
          </div>
        </div>
        
        



        <div v-if="isFullScreenMode" class="full-screen-container">
            <!-- Close Button -->
            <button class="close-btn" @click="exitFullScreen">
                <i class="bx bx-x"></i>
            </button>
            <!-- Influencer Info -->
           
            <div v-if="isFetching" class="spinner-container">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div class="reel-container">
            <div v-for="(reel, index) in reels" :key="index" class="reel" :ref="'videoPlayer_' + index">
             
                <video :id="`video-${reel.id}`" :data-index="index" class="video-js vjs-default-skin" playsinline webkit-playsinline autoplay muted controls preload="auto" @play="setCurrentPlaying(index)">
                    <source :src="reel.video_url" type="video/mp4" />
                </video>
                
                <div class="influencer-info d-flex align-items-center">
                    <img :src="reel.influencer.image" class="circle-image rounded-circle me-2"  @error="(event) => event.target.src = require('@/assets/images/default-profile.png')"/>
                    <span class="fw-bold text-light">{{ reel.influencer.first_name }} {{reel.influencer.last_name}}</span>
                </div>
                <div class="like-icon" @click="toggleLike(reel)">
                    <i :class="reel.isLiked ? 'bx bxs-heart' : 'bx bx-heart'"></i>
                </div>
            <div v-if="userInteracted" class="play-button-overlay" @click="enableAutoplay">
            <i class="bx bx-play-circle"></i>
            </div>
            <div class="circle-images pb-85">
                <router-link v-for="(product, imgIndex) in reel.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }" @click.native="enterPictureInPicture(reel, index)">
                    <img :src="product.product.image_url" class="modal-circle-image"  @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
                </router-link>
            </div>
            </div>
           
            <div ref="scrollSentinel" class="scroll-sentinel"></div>
            
            </div>
        </div>
        
        <div v-if="isInPiPMode" class="circle-images pb-85">
            <div v-for="(product, index) in currentPipProducts" :key="index">
              <img :src="product.product.image_url" class="modal-circle-image" />
            </div>
          </div>
            
                <div class="video-container-content d-none">
                    
                    <video :src="brand_videos[0].video_url" autoplay muted loop controls>
                        Your browser does not support the video tag.
                    </video>
                    <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                    <div class="logo-description-container col-md-7 ms-5">
                        <div id="main-header-indicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li data-target="#main-header-indicators" data-slide-to="0" class="active"></li>
                            </ol>
                            <br>
                            <div class="bg-grey px-5 d-flow-root subvideo-content mb-5 ">
                                <div class="d-flex justify-content-center d-none">
                                    <hr class="w-25 mx-auto text-center">
                                    </div>
                                    <div class="scroller-container">
                                    <div class="scroller">
                                        <div class="card-container justify-content-end " :style="{ transform: `translateX(-${currentPosition * (100 / visibleCards)}%)` }">
                                            <div v-for="image in image_files" :key="index" class="card">
                                                <!-- Your video content here -->
                                                <img :src="image.product.image_url" 
                                                    class="rounded-12" 
                                                    @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  >
                                                <div class="card-body text-start" >
                                                    <p class="card-text description text-white">{{ image.product.product_name }}</p>
                                                    <div class="d-flex align-items-start flex-column bd-highlight mb-3">
                                                        <p class="card-text description text-white font-italic">By {{ image.product.brand__brand_name }}</p>
                                                        <p class="card-text text-warning text-xxs">R{{ image.price }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="scroll-btn left" @click="scrollCards('left')"><i class='bx bxs-chevron-left'></i></button>
                                    <button class="scroll-btn right" @click="scrollCards('right')"><i class='bx bxs-chevron-right'></i></button>
                                </div> 
                            </div>
                        </div>
                    </div>
                    
                    <div class="text-container">
                        <div class="row">
                            <div class="bottom-left-text col-auto" data-toggle="modal" data-target="#exampleModal"> 
                                <i class='bx bx-play-circle fs-2'></i> Play
                            </div>
                            <div class="bottom-left-text col-auto bg-transparent view-products text-white">
                                <i class='bx bx-error-circle fs-2'></i>  
                                <span class="text-white ">Shop Now</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PLAY Modal -->
                <div class="modal fade hero-play-modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
                        <div class="modal-content"> 
                            <div class="modal-header">
                                <h5 class="modal-title"></h5>
                                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                                </div>
                            <div class="modal-body card border-0"> 
                            <div class="video-container  border-0">  
                                <video id="exampleModalPlayer" :src="brand_videos[0].video_url" autoplay muted loops controls>
                                    Your browser does not support the video tag.
                                </video>
                                <div class="col-md-6">
                                    <div class="hero-cards hero-product">
                                        <div class="bg-transparent">
                                            <div class="scroller-container"> 
                                                <div class="sub-scroller" ref="scroller">
                                                    <input type="hidden" ref="video_id" :value="brand_videos[0]?.id">
                                                    <div class="logo-description-container  "> 
                                                        <div class="justify-content-end">
                                                            <div :to="{name: 'product', params: {id: image.id}}" v-for="(image, index) in images" :key="index" class="card h-100">
                                                                <input type="hidden" ref="image_id" :value="image.id">
                                                                <img :src="image.product.image_url" class="rounded-12 p-1 bg-white h-150 img-fluid card-img-top" @error="(event) => event.target.src = require('@/assets/images/img-error.png')"  >
                                                                 <div class="card-body text-start"> 
                                                                    <p class="card-text description text-white"> {{ image.product.product_name }}</p>
                                                                    <p class="card-text description text-white"> {{ image.product.brand__brand_name  }}</p>
                                                                    <h5 class="card-title text-warning fw-bold">R{{ image.price }}</h5>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                </div>
        
    </div>
        
        </div>  

        <div class="container-fluid home-product">
            <h1 class="text-center my-4 ms-4 text-dark fw-bold">Latest Offers This Month</h1>
            
            <div class="row">
                <div class="card card-large" @click="navigateToCollection">
                    <router-link to="/new_collection">
                      <span class="card-text text-dark">New Collection</span>
                    </router-link>
                  </div>
                
            </div>

            <div class="row">
                <div class="card card-small home-band" @click="navigateToBrands">
                    <span class="card-text text-dark">
                        <router-link to="/view_brands" class="text-white">Brands</router-link>
                    </span>
                </div>
  
                    <div class="card card-small home-influencer" @click="navigateToInfluencers">
                        <span class="card-text text-dark">
                            <router-link to="/View_Influencer" class="text-white">Influencer </router-link>
                         </span>
                    </div>
                </div>
                
                
            </div>
    </div>

    <div v-else>

        <div class="container">
            <div class="row">
                <div class="items-found">{{ filteredProducts.length }} items found</div>

                <div class="col-md-3">
                    <Filter_product
                    @filter-products="filter_product_list"
                    />
                </div>

                <div class="col-md-9">

                    <!-- Dropdown to show combined results -->
                    <div v-if="showDropdown" class="dropdown-menu">
                        <div
                            v-for="result in filteredProducts"
                            :key="result.id"
                            @click="selectResult(result)"
                            class="dropdown-item"
                        >
                        <span class="text-start" v-if="result.type === 'product'">{{ result.product_name }}</span>
                        <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                        <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                        <span v-else>Description: {{ result.description }}</span>
                        </div>

                    </div>
                
                    <div class="product-grid">
                        <div class="product-card" v-for="product in filteredProducts" :key="product.id">
                          <div class="product-link">
                            <router-link :to="{ name: 'product', params: { id: product.id  } }" class="product-link" >
                                <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }"></div>
                              </router-link>

                            <div class=" product-info text-start">
                              <p class="product-title text-black">{{ product.product.product_name }}</p>
                              <p class="product-brand"> {{ product.product.brand__brand_name }}</p>
                              <div class="d-flex justify-content-between align-items-center">
                                <p class="product-price text-black">R{{ product.price }}</p>
                                
                                <div class="d-flex justify-content-between">
                                    <div class="cart-icon position-relative">
                                      <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addToWishlist(product.id)"></i>
                                      
                                      <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="handleProductClick(product)"></i>
                                      <div v-if="selectedProduct === product && showSizes" class="sizes show text-black">
                                        <div v-for="size in sizes" :key="size.size" class="size-select" @click="handleAddToCart(size); addToCart(product)">
                                          {{ size.size }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>      
                      </div>
                </div>
            </div>

        </div>

  </div>
</div>

<footer class="footer">
    <div class="footer-content">
        <div class="footer-links">
            <div class="footer-column">
                <h3>Trends</h3>
                <ul>
                    <li><a href="#">South Africa</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Influencer</h3>
                <ul>
                    <li><a href="#">X</a></li>
                    <li><a href="#">Instagram</a></li> 
                </ul>
            </div>
            <div class="footer-column">
                <h3>Custom Care</h3>
                <ul>
                    <li><a href="#">Contact Us</a></li>
                    <li><a href="#">Payment Method</a></li> 
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-legal">
                <p>&copy; 2024 Khiosk. All rights reserved.</p>
                <div class="footer-legal-links">
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of Use</a> 
                </div>
            </div>
        </div>
    </div>
</footer>