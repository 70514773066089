<nav class="navbar navbar-dark bg-dark fixed-top">
  <div class="container-fluid">
    <button class="btn text-white" @click="goBack">
      <i class="fas fa-arrow-left"></i>
    </button>
    <div class="ms-auto">
      <div class="nav-icons me-3 d-flex align-items-center">
        <div class="icon-container me-3" @click="toggleCart" ref="iconContainer">
          <a href="#">
            <i class='bx bx-shopping-bag text-white' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        
        <div class="icon-container" data-dropdown>
          <a href="#"><i class='bx bx-user text-white'></i></a>
          <div class="dropdown-content"> 
            <router-link to="/register" class="">
              <span class="text-dark" >Sign Up</span>
            </router-link>
       
            <router-link to="/login" class=" ">
              <span class="text-dark">Login</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
    <div class="view-influencer-app-content">
      <div class="card border-0 bg-transparent">
    
        <div class="profile-header-view rounded-12 position-relative">
          
          <div class="View-influencer d-flex justify-content-center align-items-center">
            <div class="top-right-buttons">
              <button class="btn btn-outline-dark text-black btn-sm" data-toggle="tooltip" data-placement="bottom" title="Share Profile"><i class='bx bx-share-alt' ></i>  </button>
            </div>
            <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img w-auto" id="profileImage">
            <div class="influencer_info">
              <p class="text-start profile-name fw-bolder">{{ form.first_name }} {{ form.last_name }}</p>
              <p class=" text-start bio">{{ form.bio }}</p>
            </div>
          </div>
        </div> 
    
        <ul class="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-content-tab" data-bs-toggle="pill" data-bs-target="#pills-content" type="button" role="tab" aria-controls="pills-content" aria-selected="true"><i class='bx bx-video'></i> Content</button>
          </li>
        </ul>
    
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">
            <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>
        
            <div v-else class="row">
                <div class="view-video-lists">
                  <div v-for="(video, index ) in videos" :key="index" class="video-card position-relative">
                    <div ref="videoPlayer_{{ index }}"  @click="openFullScreen(video.id, index)">

                      <video :src="video.video_url"  class="rounded-12 img-fluid h-250 object-fit-cover w-100">
                        Your browser does not support the video tag.
                      </video>
                
              <div class="circle-images d-flex justify-content-center pb-85 ms-3">
                <div v-for="(image, index) in video.products" :key="index" class="text-center">
                  <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                    <input type="hidden" ref="image_id" :value="image.product.id">
                    <img 
                      v-if="image.product && image.product.image_url" 
                      :src="image.product.image_url" 
                      class="circle-image" 
                      @error="(event) => event.target.src = require('@/assets/images/img-error.png')" 
                      alt="Product Image"
                    >
                  </router-link>
                </div>
              </div>
                  <div class="text-start mt-2 ms-1 text-sm">
                    <h6 class="fw-bold">{{ video?.caption || 'No Caption' }}</h6>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isFullScreenMode" class="full-screen-container">
          <!-- Close Button -->
          <button class="close-btn" @click="exitFullScreen">
              <i class="bx bx-x"></i>
          </button>
          <!-- Influencer Info -->
         
          <div v-if="isFetching" class="spinner-container">
              <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
              </div>
          </div>

          <div class="reel-container">
          <div v-for="(reel, index) in reels" :key="index" class="reel" :ref="'videoPlayer_' + index">
        
              <video :id="`video-${reel.id}`" :data-index="index" class="video-js vjs-default-skin" playsinline autoplay muted controls preload="auto" @play="setCurrentPlaying(index)">
                  <source :src="reel.video_url" type="video/mp4" />
              </video>
              <div class="influencer-info d-flex align-items-center">
                  <img :src="profileImage" class="circle-image rounded-circle me-2"  @error="(event) => event.target.src = require('@/assets/images/default-profile.png')"/>
                  <span class="fw-bold text-light">{{ reel.influencer.first_name }} {{reel.influencer.last_name}}</span>
              </div>
              <div class="like-icon" @click="toggleLike(reel)">
                  <i :class="reel.isLiked ? 'bx bxs-heart' : 'bx bx-heart'"></i>
              </div>
          <div v-if="userInteracted" class="play-button-overlay" @click="enableAutoplay">
          <i class="bx bx-play-circle"></i>
          </div>
          <div class="circle-images pb-85">
              <router-link v-for="(product, imgIndex) in reel.products.filter(p => p.product_details)" :key="imgIndex" :to="{ name: 'product', params: { id: product.product_details.id } }" @click.native="enterPictureInPicture(reel, index)">
                  <img :src="product.product.image_url" class="modal-circle-image"  @error="(event) => event.target.src = require('@/assets/images/img-error.png')">
              </router-link>
          </div>
          </div>
         
          <div ref="scrollSentinel" class="scroll-sentinel"></div>
          
          </div>
      </div>

          </div>
          <div class="tab-pane fade" id="pills-pending-aproval" role="tabpanel" aria-labelledby="pills-pending-aproval-tab">...
            
          </div> 
        </div>
        