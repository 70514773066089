<div class="side-panel left"></div>
<div class="side-panel right"></div>

<div class="app h-100-vh">
  <b-container>
    <b-row>
      <b-col>
        <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo mb-5" class="kyosk-logo">
        <b-form @submit.prevent="onSubmit" class="login-form">

          <b-form-group id="emailGroup" label="Email Address:" label-for="emailInput">
            <b-form-input type="email" id="email" v-model="form.email" required></b-form-input>
          </b-form-group>
          <b-form-group id="passwordGroup" label="Password:" label-for="passwordInput">
            <b-input-group>
              <b-form-input :type="passwordType" id="password" v-model="form.password"  required></b-form-input>
              <b-input-group-append>
                <b-button variant="btn btn-outline-dark text-black" @click="togglePassword">
                  <i :class="passwordType === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'" id="togglePassword"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-col md="6">
            <b-link href="/forgot-password" class="forgot-password-link">Forgot Password?</b-link>
          </b-col>

          <button type="submit" variant="primary" class="login-button">Login</button>
        </b-form>
        <b-row>
          <b-col md="6">
            <b-link href="/register" class="">Dont Have An Account?: Sign Up</b-link>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </b-container>
</div>
