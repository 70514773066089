<div class="home-navbar ">
    
  <div class="top-banner">
    <div class="row"> 
      <div class="d-flex justify-content-center align-items-center">
          Free delivery on orders over R1,000.00
      </div>
      
    </div>
  </div>
 
  <nav class="first-navbar">
    <i class='bx bx-menu fs-4 text-dark ms-3 close-sidebar' @click="toggleSidebar"></i> 
    

    <img :src="require('@/assets/images/new_logo_organge_black.png')" alt="Kyosk Logo" class=" logo" @click="goToLandingPage" >
    <div class="search-bar search-bar-lg">
        <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
        <div class="search-dropdown" id="search-dropdown">
            <a href="#">Popular search 1</a>
            <a href="#">Popular search 2</a>
            <a href="#">Popular search 3</a>
            <a href="#">Popular search 4</a>
            <a href="#">Popular search 5</a>
        </div>
    </div>
    <div class="nav-icons">
      <div>
          <a href="#" data-toggle="modal" data-target="#uploadModal" class="text-black">
            <i class="fa-regular fa-square-plus nav-icon text-black me-2 fs-5"></i>
          </a>
      </div>
      <div class="icon-container" @click="toggleRightSidebar" ref="iconContainer">
          <a href="#">
            <i class='bx bx-shopping-bag' id="cart-icon"></i>
            <span class="cart-quantity" id="cart-quantity">{{ cartQuantity }}</span>
          </a>
        </div>
        <div class="icon-container" data-dropdown>
            <a href="#"><i class='bx bx-user'></i></a>
            <div class="dropdown-content"> 
                <router-link to="/Influencer_profile" class="">
                  <span class="text-dark" >Profile</span>
                </router-link>
       
                  <a href="#" @click="logout">
                    <span class="text-dark">logout</span>
                  </a>
            </div>
        </div>       
    </div>
  </nav>
  
  <div class="search-bar mt-3 search-bar-sm">
    <input type="text" placeholder="Search for items and brands" id="search-input" :value="search_input" @input="update_search">
    <div class="search-dropdown" id="search-dropdown"> 
    </div>
</div>
 <br>
  <nav class="second-navbar" :class="{'sidebar-visible': isSidebarVisible}">
    <div class="nav-item" data-dropdown>
        <a href="#">NEW IN</a>
        <div class="dropdown-content">
            <a href="#">Women</a>
            <a href="#">Men</a>
            <a href="#">Kids</a>
        </div>
    </div>
    <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item" data-dropdown>
      
      <a href="#" class="department-name">{{ department.department_name }}</a>
      <div class="dropdown-content align-items justify-content-center">
        <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex" class="subcategory-section">
          <a href="#" class="category-name">{{ category.category_name }}</a>
          <div v-if="category.subcategories.length" class="subcategory-container">
            <router-link 
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
              :key="subcategoryIndex" 
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-item" data-dropdown>
        <a href="/view_brands">BRANDS</a>
    </div>
  </nav>
</div>

<div class="influencer-category-navbar-sm">
<!-- Main Sidebar -->
<div :class="{'open': sidebarOpen}" id="sidebar">
  

  <div class="close-btn text-black close-sidebar" @click="toggleSidebar">
    <i class='bx bx-x'></i>
  </div>
  <!-- Departments Loop -->
  <div v-for="(department, departmentIndex) in departments" :key="departmentIndex" class="nav-item department">
    <a href="#" class="sidebar-item department-name-sidebar text-start" @click.prevent="toggleSubSidebar(departmentIndex)">
      {{ department.department_name }}
    </a>
    
    <!-- Sub-Sidebar for Categories -->
    <div :class="{'open': subSidebarOpen[departmentIndex]}" id="subSidebar" v-if="department.categories.length">
      <a href="#" class="back-btn start-0" @click="toggleSubSidebar(departmentIndex)"><i class='bx bx-arrow-back'></i> {{department.department_name }}</a>
      
      <div v-for="(category, categoryIndex) in department.categories" :key="categoryIndex">
        <a href="#" 
        class="sidebar-item dropdown-toggle category-name text-start d-flex justify-content-between" 
        data-bs-toggle="collapse" 
           :data-bs-target="'#categoryDropdown' + departmentIndex + categoryIndex" 
           aria-expanded="false" 
           :aria-controls="'categoryDropdown' + departmentIndex + categoryIndex">
          {{ category.category_name }}
        </a>
  
        <!-- Only show subcategories when toggle button is clicked -->
        <div :id="'categoryDropdown' + departmentIndex + categoryIndex" class="collapse">
          <div v-if="category.subcategories.length" class="subcategory-container text-black text-start">
            <router-link 
              v-for="(subcategoryName, subcategoryIndex) in category.subcategories" 
              :key="subcategoryIndex" 
              :to="{ name: 'subcategory-products', params: { subcategory: subcategoryName.subcategory_name } }">
              {{ subcategoryName.subcategory_name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nav-item text-start">
    <a href="/view_brands">BRANDS</a>

</div>
</div>
</div>

<!-- Sidebar Container -->
<div id="right-sidebar" :class="{'open': cartQuantity > 0 && isRightSidebarOpen}">
  <!-- Close Button -->
  <div class="view-cart-header d-flex justify-content-between align-items-center fixed-top">
    <h4 class="text-start fw-bold text-black ml-3">Cart</h4>
    <div class="close-btn text-black" @click="isRightSidebarOpen = false">
      <i class='bx bx-x'></i>
    </div>
  </div>
  <!-- Sidebar Content -->
  <div class="sidebar-content">
    <div v-for="item in cartItems" :key="item.id" class="cart-item">
      <img :src="item.image" alt="Product image" class="cart-item-image" />
      <div class="cart-item-details d-flex text-black">
        <h4 class="product-title fw-bold text-black mb-0">{{ item.product_name }}</h4>
        
        <p class="product-brand mb-1"> {{ item.brand }}</p>
        <p class="mb-0">size: {{ item.size }}</p>
        <p class="mb-2">color: {{ item.color }}</p>
        <p class="fw-bold">Price: R {{ formatPrice(item.price * item.quantity) }}</p>

        <div class="d-flex align-items-center">
          <span class="me-2">Qty:</span>
          <button v-if="item.quantity === 1" class="btn btn-outline-secondary btn-sm text-black" @click="deleteCartItem(item.product_id)">
            <i class="fa-solid fa-trash"></i>
          </button>
          <button v-else class="btn btn-outline-secondary btn-sm text-black" @click="decreaseQuantity(item)">-</button>
          <span class="quantity mx-2">{{ item.quantity }}</span>
          <button class="btn btn-outline-secondary btn-sm text-black" @click="increaseQuantity(item)">+</button>
      </div>
      </div>
    </div>
  </div>
  <div class="view-cart-btn">
    <a href="/shipping_address" class="add-to-cart text-white">Checkout <span class="mx-3">-</span> R{{ cartTotal }}</a>
  </div>
</div>

<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close-btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title" id="uploadModalLabel">Video Upload</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- Video Section -->
          <div class="col-md-6">
            <div class=" ">
              <label for="videoUpload" class="upload-label" id="uploadLabel">
                <video id="videoPreview" controls class="video-preview"></video>
              </label>
              <input type="file" id="video_url" accept="video/*" class="file-inputs" @change="previewVideo">
            </div>
          </div>
          <!-- Form Section -->
          <div class="col-md-6">
            <div class="form-group">
              <label for="caption">Caption:</label>
              <input type="text" v-model="caption" id="caption" class="form-control form-control-sm">
              <small v-if="showCaptionError" class="text-danger">Please provide a caption.</small>
            </div>
            <ul class="nav nav-tabs mt-4" id="my_tab" role="tablist">
              <li class="nav-item w-50" role="presentation">
                <button class="nav-link active" id="tag-tab" data-bs-toggle="tab" data-bs-target="#tag" type="button" role="tab" aria-controls="tag" aria-selected="true" @click="switchTab('tag')">Tag Products</button>
              </li>
              <li class="nav-item w-50" role="presentation">
                <button class="nav-link" id="upload-tab" data-bs-toggle="tab" data-bs-target="#upload" type="button" role="tab" aria-controls="upload" aria-selected="false" @click="switchTab('upload')">Upload Products</button>
              </li>
            </ul>
            <div class="tab-content" id="my_tab_content">
              <div class="tab-pane fade show active" id="tag" role="tabpanel" aria-labelledby="tag-tab">
                <!-- Tag Products tab content -->
        
                <div class="form-group position-relative">
                  <label for="brand">Tag Brand/Influencer:</label>
                  <input type="text" v-model="tagInput" id="tagInput" class="form-control" placeholder="Type @ to tag brands" @keyup="filterBrands" @blur="validateBrand">
                  <!-- Dropdown for brands -->
                  <ul v-if="showBrandDropdown" class="dropdown-menu show">
                    <li v-for="brand in filteredBrands" :key="brand.id" @click="selectBrand(brand)" class="dropdown-item">
                      {{ brand.brand_name }}
                    </li>
                  </ul>
                  <small v-if="brandError" class="text-danger">Please select a valid brand from the list.</small>
                </div>
                <div class="form-group position-relative">
                  <label for="productSearch">Search Products:</label>
                  <input type="text" v-model="searchQuery" id="products" class="form-control" placeholder="Type product name" @keyup="filterTaggedProducts">
                  <!-- Dropdown for products -->
                  <ul v-if="showProductDropdown" class="dropdown-menu show">
                    <li v-for="product in filtered_tagged_products" :key="product.product_id" @click="selectProduct(product)" class="dropdown-item d-flex align-items-center">
                      <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                      <span>{{ product.product.product_name }}</span>
                    </li>
                  </ul>
                </div>
                <!-- Display selected products -->
                <div v-if="selectedProducts.length > 0">
                  <h5>Selected Products:</h5>
                  <ul>
                    <li v-for="product in selectedProducts" :key="product.id" class="d-flex align-items-center">
                      <img :src="product.product.image_url" alt="Image" class="product-image-thumbnail mr-2">
                      <span>{{ product.product.product_name }}</span>
                      <span @click="removeProduct(product)" class="ml-2 text-danger" style="cursor: pointer;">&times;</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab-pane fade" id="upload" role="tabpanel" aria-labelledby="upload-tab">
                <!-- Upload Products tab content -->

                <div v-if="uploadedProducts.length > 0">
                  <h6>Uploaded Products:</h6>
                  <ul>
                    <li v-for="(product, index) in uploadedProducts" :key="index" class="d-flex align-items-center">
                      <img :src="product.imagePreviewUrl" alt="Image" class="product-image-thumbnail mr-2">
                      <span>{{ product.product_name }}</span>
                      <span @click="removeUploadedProduct(product)" class="ml-2 text-danger" style="cursor: pointer;">&times;</span>
                    </li>
                  </ul>
                </div>

                <div class="modal-body">
                  <!-- Add the form tag here -->
                  <form id="uploadForm" >
                    <div id="imageUploadContainer">
                      <div class="upload-group">
                        <div class="form-section">
                          <div class="row">
                            <!-- Image Upload Section -->
                              <div class="image-container">
                                <div class="upload-box-image">
                                  <label for="image_url" class="upload-label">
                                    <div v-if="!imageUploaded" class="upload-icon" id="uploadIcon">📤</div>
                                    <p v-if="!imageUploaded" id="uploadText">Upload Image</p>
                                    <img id="imagePreview" class="image-preview">
                                  </label>
                                </div>
                              </div>
                              <input type="file" id="image_url" accept="image/*" class="file-inputs" @change="previewImage" multiple>
          
                            <!-- Form Fields Section -->
                              <div class="form-group">
                                <label for="productName" class="text-label">Product Name:</label>
                                <input type="text" id="product_name" name="productName" class="form-control form-control-sm" required>
                              </div>
          
                              <div class="form-group mt-2">
                                <label for="description" class="text-label">Description:</label>
                                <textarea id="description" name="description" class="form-control form-control-sm" required></textarea>
                              </div>
          
                              <div class="form-group mt-2">
                                <label for="no_of_items" class="text-label">No of Stock:</label>
                                <input type="text" id="no_of_items" class="form-control form-control-sm" required>
                              </div>
          
                              <div class="form-group mt-2">
                                <label for="department" class="text-label">Department:</label>
                                <select id="department" name="department" class="form-control form-control-sm" v-model="selectedDepartment" @change="updateCategories" required>
                                  <option v-for="department in departments" :key="department.id" :value="department.id">
                                    {{ department.department_name }}
                                  </option>
                                </select>
                                <input v-if="selectedDepartment === 'Other'" type="text" id="department_input" name="department-input" class="form-control form-control-sm mt-2" v-model="newDepartment" @keyup.enter="addDepartment" placeholder="Type a new department">
                              </div>
          
                              <div class="form-group mt-2">
                                <label for="categories" class="text-label">Category:</label>
                                <select id="categories" name="categories" class="form-control form-control-sm" v-model="selectedCategory" @change="updateSubcategories" required>
                                  <option v-for="category in categories" :key="category.id" :value="category.id">
                                    {{ category.category_name }}
                                  </option>
                                </select>
                                <input v-if="selectedCategory === 'Other'" type="text" id="category-input" name="category-input" class="form-control form-control-sm mt-2" v-model="newCategory" @keyup.enter="addCategory" placeholder="Type a new category">
                              </div>
          
                              <div class="form-group mt-2">
                                <label for="sub_category" class="text-label">Sub-Category:</label>
                                <select id="sub_category" name="subcategories" class="form-control form-control-sm" v-model="selectedSubcategories" required>
                                  <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                                    {{subcategory.subcategory_name}}
                                  </option>
                                </select>
                              </div>

                              <div class="form-group mt-2">
                                <label for="color" class="text-label">Color:</label>
                                <input type="text" class="form-control form-control-sm" id="color" name="colorSmall" required>
                              </div>

                              <div class="form-group mt-2">
                                <label for="price" class="text-label">Price:</label>
                                <input type="text" class="form-control form-control-sm price" id="price" name="color_price" required>
                              </div>
          
                            <!-- Size and Color Options Section -->
                            <div class="form-group mt-3">
                              <div class="table-container" style="padding: 15px; overflow-x: auto;">
                                <table class="table table-bordered" style="min-width: 250px;">
                                  <thead>
                                    <tr>
                                      <th>Size</th>
                                      <th>Size Quantity</th>
                                    </tr>
                                  </thead>
                                  <tbody id="size_table">
                                    <tr class="size-color-row">
                                      <td class="size-input">
                                        <input type="text" class="form-control form-control-sm" id="size" name="size_input" required>
                                      </td>
                                      <td class="size-quantity">
                                        <input type="number" class="form-control form-control-sm" id="size_quantity" name="size_quantity" required>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="">
                                <button type="button" id="addMore" class="action-btn add-size">add size</button>
                                <button type="button" class="action-btn" @click="uploadImage">Save</button>
                              </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                
              </div>
            </div>
          </div>
          <br>
          <div class="modal-footer">
            <button type="submit" class="action-btn" @click="handleUpload()">Upload</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
