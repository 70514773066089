<div class="home-navbar ">
  <Influencer_sidebar />
</div>

<div class=" app-content influencer-profile-sm ">
  <Influencer_navbar v-model:search_input="search_query" @update:search_input="update_search_query"/>
  <div v-if="!search_query">
    <!-- Your content here -->
    <div class="card border-0 bg-transparent">

      <div class="profile-header rounded-12 position-relative">
        <div class="top-right-buttons">
          <button class="btn btn-dark btn-sm me-2" @click="showEditProfileModal = true" data-toggle="tooltip" data-placement="bottom" title="Edit Profile">
            <i class='bx bx-pencil '></i> Profile
          </button>
          <button class="btn btn-outline-dark text-black btn-sm" data-toggle="tooltip" data-placement="bottom" title="Share Profile"><i class='bx bx-share-alt' ></i>  </button>

        </div>
        <div class="profile-influencer d-flex justify-content-center align-items-center">
          <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img w-auto" id="profileImage">
          <div class="influencer_info">
            <p class="text-start profile-name fw-bolder">{{ form.first_name }} {{ form.last_name }}</p>
            <p class=" text-start bio">{{ form.bio }}</p>
          </div>
        </div>

      </div> 

      <ul class="nav nav-pills justify-content-center mt-1" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-content-tab" data-bs-toggle="pill" data-bs-target="#pills-content" type="button" role="tab" aria-controls="pills-content" aria-selected="true"><i class='bx bx-video'></i> Content</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="false"><i class='bx bx-wallet'></i> Wallet</button>
        </li> 
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-content" role="tabpanel" aria-labelledby="pills-content-tab">
          <div v-if="videos.length === 0" class="text-center my-4">Loading videos...</div>

          <div v-else class="row">
            <div class="view-video-lists">
              <div v-for="video in videos" :key="video.id" class="video-card position-relative">
                <img v-if="video.thumbnail_url" :src="video.thumbnail_url" alt="Video Thumbnail" class="thumbnail-image rounded-12 img-fluid h-250 object-fit-cover w-100" @error="(event) => event.target.src = require('@/assets/images/img-error.png')" />
                                        
                <!-- Show Video if thumbnail_url does not exist -->
                <video v-else preload="auto" playsinline webkit-playsinline muted :data-src="video.video_url" class="video-js vjs-default-skin rounded-12 img-fluid h-250 object-fit-cover w-100">
                    <source :src="video.video_url" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
                <!-- Display video with relative position -->

                <!-- Display associated product images on top of the video -->
                <div class="circle-images d-flex justify-content-center pb-85">
                  <div v-for="(image, index) in video.products" :key="index" class="text-center">
                    <router-link v-if="image.product && image.product_details" :to="{name: 'product', params: {id: image.product_details.id}}">
                      <input type="hidden" ref="image_id" :value="image.product.id">
          
                      <img 
                        v-if="image.product && image.product.image_url" 
                        :src="image.product.image_url" 
                        class="circle-image" 
                        @error="(event) => event.target.src = require('@/assets/images/img-error.png')" 
                        alt="Product Image"
                      >
                    </router-link>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-start text-sm">
                    <h6 class="fw-bold">{{ video.caption }}</h6>
                  </div>
                  <div class="text-end text-sm">
                    <button class="btn btn-sm text-black dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li><a class="dropdown-item" >Edit</a></li>
                      <li><a class="dropdown-item" @click="deleteVideo(video.id)">Delete</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Display video caption -->

            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab">
          <div class="container pt-4">
            <div class="row text-center">

              <div class="row text-center mt-4">
                <!-- Pending Orders -->
                <div class="col-md-6">
                  <div class="card shadow-sm">
                    <div class="card-body">
                      <p class="mb-1 text-muted">Pending:</p>
                      <h5 class="card-title">R0.00</h5>
                    </div>
                  </div>
                </div>
                <!-- Total Balance -->
                <div class="col-md-6">
                  <div class="card shadow-sm">
                    <div class="card-body">
                      <p class="mb-1 text-muted">Total Balance:</p>
                      <h5 class="card-title">R0.00</h5>
                    </div>
                  </div>
                </div>
              </div>
          
              <!-- Withdrawal Section -->
              <div class="row justify-content-center align-items-center mt-3">
                <div class="col-md-6 mb-3">
                  <div class="card shadow-sm">
                    <div class="card-body">
                      <p class="text-muted mb-2">Withdrawal </p>
                      <!-- Input for Withdrawal -->
                      <div class="input-group mb-3">
                        <span class="input-group-text">ZAR</span>
                        <input type="number" class="form-control" placeholder="Enter amount" min="1" />
                      </div>
                      <button class=" action-btn text-white btn-sm">Payout</button>
                    </div>
                  </div>
                </div>
              </div>
          
              <!-- Payment History Section -->

            </div>
            <div class="mt-4">
              <!-- Align Heading to the Start -->
              <h5 class="text-start">Payment History</h5>
            
              <!-- Center Icon and Text -->
              
            </div>
            <div class="mt-4">
              <div class="text-center text-muted">
                <i class="bx bx-wallet fs-1 mb-1"></i>
                <p>No payment history available</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    </div>
  </div>
  <div v-else>
    <div class="container">
      <div class="row">
          <div class="col-md-3">
              <Filter_product
              @filter-products="filter_product_list"
              />
          </div>

          <div class="col-md-9">
              <div class="items-found">{{ filteredProducts.length }} items found</div>

              <!-- Dropdown to show combined results -->
              <div v-if="showDropdown" class="dropdown-menu">
                  <div
                      v-for="result in filteredProducts"
                      :key="result.id"
                      @click="selectResult(result)"
                      class="dropdown-item"
                  >
                  <span v-if="result.type === 'product'">{{ result.product_name }}</span>
                  <span v-else-if="result.type === 'brand'">Brand: {{ result.brand__brand_name }}</span>
                  <span v-else-if="result.type === 'category'">Category: {{ result.category__category_name }}</span>
                  <span v-else>Description: {{ result.description }}</span>
                  </div>
              </div>
          
              <div class="product-grid">
                  <router-link :to="{name: 'product', params: {id: product.id }}" class="product-card" v-for="product in filteredProducts" :key="product.id">
                      <input type="hidden" ref="image_id" :value="product.id">
                      <div class="product-image" :style="{ backgroundImage: `url(${product.product.image_url})` }">          
                      </div>
                      <div class="product-info text-start">
                          <p class="product-title text-black">{{ product.product.product_name }}</p>
                          <p class="product-brand">{{ product.product.brand__brand_name }}</p>
                          <div class="d-flex justify-content-between align-items-center">
                                  <p class="product-price text-black">R{{ product.price }}</p>
                                  <div class="d-flex justify-content-between">
                                    <i class="fa-sharp fa-regular fa-heart text-black fa-lg me-2" @click.stop="addWishlist(product)"></i>
                                    <i class="fa fa-cart-plus text-black fa-lg" aria-hidden="true" @click.stop="addToCart(product)"></i>
                                  </div>
                                </div>
                      </div>
                  </router-link>
              </div>
          </div>
      </div>
  </div>
</div>
</div>

<div v-if="showEditProfileModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block; background-color: rgba(0, 0, 0, 0.5);">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <span class="close-btn" @click="closeModal">&times;</span>
        <h5 class="modal-title">Profile</h5>
        <button type="button" class="close" @click="showEditProfileModal = false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-center mb-3">           
          <img :src="profileImage || require('@/assets/images/profile-picture-size.png')" alt="Profile Picture" class="profile-picture-img-modal w-auto"  id="modalProfileImage">
          <i class="bx bxs-camera imge-edit-modal" 
              data-toggle="tooltip" 
              data-placement="bottom" 
              title="Change Profile Picture" 
              @click="triggerFileInput" 
              id="uploadIcon">
          </i> 
          <input ref="fileInput" type="file" accept="image/*" @change="previewImage" style="display: none;">
        </div>
          <form @submit.prevent="updateUser">

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="first_name" class="text-label">First name:</label>
                <input type="text" class="form-control" v-model="form.first_name" id="first_name">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="last_name" class="text-label">Last name:</label>
                <input type="text" class="form-control" v-model="form.last_name" id="last_name">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="text-label">Email address:</label>
                <input type="email" class="form-control" v-model="form.email" id="email">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone_number" class="text-label">Phone number:</label>
                <input type="text" class="form-control" v-model="form.phone_number" id="phone_number">
              </div>
            </div>
            <div class="form-group">
              <label for="bio" class="text-label">Bio:</label>
              <textarea class="form-control" v-model="form.bio" id="bio"></textarea>
          </div>
            
          </div>
        </form>
      </div>
      <div class="modal-footer">
          <button type="button" class="action-btn" @click="updateUser">Save changes</button>
      </div>
    </div>
  </div>
</div>